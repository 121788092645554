@import url(main.css);

/* ========== START - Auth pages CSS ========== */
.auth-section {
  position: relative;
  background-color: var(--neutral-10);
}

.auth-section .container-fluid {
  padding-inline: 0;
}

.cs-left-section {
  position: relative;
  height: 100vh;
  background: var(--primary-canvas-surface);
  overflow: hidden;
}

.forgot-password .btn.cs-btn-primary {
  margin-top: 30px;
  margin-bottom: 0;
}

.cs-left-section .auth-image {
  /* position: relative;
  width: 100%;
  height: calc(100% - 13vw);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; */
  position: relative;
  width: 100%;
  height: calc(100% - 8vw);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cs-left-section .auth-image img {
  position: relative;
  max-width: 100%;
  /* margin-top: -13vh; */
}

.cs-left-section .cs-content-box {
  position: relative;
  top: 7vh;
  left: 8vh;
  width: 400px;
  z-index: 2;
}

.cs-left-section .cs-content-box h1 {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 0;
}

.cs-left-section .cs-content-box p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 380px;
  margin-top: 15px;
}

.cs-left-section .cs-content-box .cs-btn-secondary {
  margin-top: 24px;
  -webkit-box-shadow: var(--box-shadow-one);
  box-shadow: var(--box-shadow-one);
  background-color: transparent;
  max-width: 124px;
}

.cs-right-section {
  position: relative;
  height: calc(100vh - 30px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  padding-inline: 9.3vw;
}

.cs-right-section .cs-logo {
  margin-bottom: 32px;
}

.auth-section .cs-title {
  position: relative;
}

.auth-section .cs-title::after {
  content: '';
  position: relative;
  display: block;
  bottom: 0;
  left: 0;
  max-width: 282px;
  height: 2px;
  margin: 16px auto;
  background-color: var(--primary-main);
}

.sign-up-section .cs-auth-header,
.sign-in-section .cs-auth-header {
  margin-bottom: 8px;
}

.auth-sign-up-scrollbar {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 620px;
  margin-inline: -6px;
  padding-inline: 6px;
}

.cs-auth-form .cs-modal-btn {
  margin-top: 32px;
}

.auth-footer {
  margin-block: 24px;
  text-align: center;
}

.auth-footer button {
  margin: 0 auto;
  margin-block: 32px;
}

.auth-main-link {
  display: inline-block;
  border: 1px solid var(--neutral-50);
  background-color: var(--neutral-10);
  padding: 11px 36px;
  border-radius: 50px;
  color: var(--neutral-100);
}

.auth-section .cs-right-section .cs-auth-header p {
  padding-inline: 40px;
  margin-bottom: 24px;
}

.auth-common-section .cs-right-section .cs-auth-header p {
  margin-bottom: 0;
  padding-inline: 5vw;
}

.mfa-row {
  position: relative;
  padding-inline: 80px;
}

.mfa-row .col-lg-12:last-child .mfa-box {
  margin-bottom: 5px;
}

.auth-section.verification-section .cs-form-check-box.form-check {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.auth-section.verification-section .auth-footer button {
  margin-bottom: 16px;
}

.email-verification-section .auth-footer {
  margin-block: 32px 16px;
}

.email-verification-section .cs-right-section p,
.auth-common-section .cs-right-section p {
  padding-inline: 6vw;
  margin-block: 0;
}

.email-verification-section .auth-footer h3 {
  margin-bottom: 8px;
}

.email-verification-section .auth-footer button.cs-btn-primary {
  margin-block: 0;
}

.qr-code {
  margin-bottom: 32px;
}

.qr-code img {
  border: 1px solid var(--neutral-50);
  border-radius: 5px;
  padding: 8px;
}

.qr-code-modal {
  text-align: center;
}

.qr-code-modal .qr-code {
  margin-block: 24px 0;
}

.auth-section .forgot-password .cs-auth-header .text-center {
  margin-bottom: 32px;
}

.auth-section .verify-email-address .cs-auth-header .text-center {
  margin-bottom: 0;
}

.sign-up-section .icon-error-msg {
  margin-top: 8px;
}

/* ========== Review info section Start ========== */
.review-info-section .auth-sign-up-scrollbar {
  max-height: 690px;
}

.review-info-section .cs-auth-form .form-label {
  color: var(--neutral-60);
}

.review-info-section .cs-auth-form .select-dropdown .css-1fdsijx-ValueContainer {
  padding-inline: 8px;
}

.review-info-section .cs-auth-form .select-dropdown .css-894a34-indicatorSeparator,
.individual-main-section .cs-add-row .add-account-drop.for-mob,
.companies-main-section .company-add-drop .cs-add-row.for-mob {
  display: none;
}

.review-info-section .cs-auth-form .select-dropdown .css-1hb7zxy-IndicatorsContainer::before {
  right: 10px;
  background-color: var(--neutral-60);
}

.review-info-section .cs-auth-form .select-dropdown .cs-disabled .css-16xfy0z-control {
  pointer-events: all;
  cursor: no-drop !important;
  border: 0 !important;
  background: var(--neutral-40);
}

.review-info-section .cs-auth-form .select-dropdown .css-16xfy0z-control {
  padding-inline: 6px;
}

.review-info-section .cs-auth-form .select-dropdown .css-olqui2-singleValue {
  color: var(--neutral-60) !important;
  font-weight: 300;
}

.review-info-section .cs-auth-form .cs-neutral-90 svg path {
  fill: var(--neutral-60) !important;
}

.review-info-section .cs-auth-form .cs-form-textarea .cs-textarea,
.review-info-section .cs-auth-form .cs-date-picker-calender {
  background: var(--neutral-40);
  color: var(--neutral-60);
  border-color: var(--neutral-40);
  cursor: not-allowed;
}

.back-to-sign-in .btn {
  text-decoration: none;
}

.back-to-sign-in .cs-btn-tertiary {
  margin: 0 auto;
}

/* ========== Review info section End ========== */

/* ========== Complete profile CSS Start ========== */
.cs-complete-profile>h4,
.cs-complete-profile .cs-avatar {
  margin-bottom: 24px;
}

.cs-profile-update p.cs-regular-sub-heading-xs {
  padding-top: 8px;
}

.cs-avatar-container {
  margin-top: 24px;
}

.cs-avatar-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 8px;
}

.cs-avatar-row .cs-avatar-xs {
  width: 60px;
  height: 60px;
}

.cs-avatar-row .profile {
  position: relative;
  border: 2px solid transparent;
  border-radius: 100%;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  cursor: pointer;
  background: var(--neutral-20);
}

.profile-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  opacity: 1;
}

.cs-avatar-row .profile:hover,
.cs-avatar-row .profile.selected-avatar {
  border-color: var(--primary-main);
}

.complete-profile-modal .modal-footer {
  padding: 0;
}

.cs-avatar-row .profile span.cs-primary {
  position: absolute;
  bottom: -2px;
  right: 0;
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.cs-avatar-row .profile.selected-avatar span.cs-primary {
  opacity: 1;
}

.image-crop-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.image-crop-area canvas {
  margin-top: 5px;
  width: 100% !important;
  height: 100% !important;
  border: 2px dashed var(--neutral-40);
  border-radius: 4px;
}

/* ========== Complete profile CSS End ========== */

.form-control.PhoneInput .PhoneInputCountry {
  position: absolute;
  top: 50%;
  left: 16px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.cs-form-group div,
.stripe_card_content_body_area {
  position: relative;
}

.PhoneInput .PhoneInputCountrySelect,
.PhoneInput .PhoneInputCountrySelectArrow {
  display: none;
}

.tooltip-inner .content-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-align: left;
}

.tooltip-inner div span.cs-neutral-10 {
  margin-right: 6px;
}

.tooltip-inner div {
  text-align: left;
}

.input-field-icon.cursor-pointer.password-info {
  right: 44px;
}

span.cs-regular-body-text-m.cs-danger svg,
span.cs-regular-body-text-m.cs-success svg {
  margin-right: 8px;
}

.cs-password-input .input-field-icon {
  margin-top: 11px;
}

/* ========== END - Auth pages CSS ========== */

/* ========== Sidebar and Navbar CSS start ========== */
.cs-round {
  border-radius: 50%;
}

.cs-menu-toggle,
.cs-cursor-pointer {
  cursor: pointer;
}

.cs-relative {
  position: relative;
}

.cs-header .nav-link {
  padding: 0 !important;
}

.cs-verified {
  position: absolute;
  right: -1px;
  bottom: 0;
}

.smooth-transition {
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
}

.cs-md-modal .log-out-popup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.cs-md-modal .log-out-popup p {
  margin-top: 24px;
}

/* main layout End */
/* Sidebar Start */
.cs-sidebar-nav.nav {
  display: block;
}

.cs-sidebar-main-widget {
  max-width: 272px;
  position: fixed;
  padding: 16px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background: var(--neutral-10);
  border-right: 1px solid var(--neutral-30);
  z-index: 3;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.cs-sidebar-main-widget .cs-btn-icon-primary {
  max-width: 240px;
  padding-block: 12px;
  max-height: 40px;
}

.sidebarClose .cs-sidebar-bottom span.add-filled {
  margin-right: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.sidebarClose .cs-btn-icon-primary {
  max-width: 48px;
  max-height: 48px;
  padding: 12px;
}

.cs-nav-item .cs-side-nav,
.cs-nav-dropdown>a,
.cs-nav-dropdown>span {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  background: var(--neutral-10);
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 8px 16px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  text-transform: capitalize;
}

.sidebarClose .cs-sidebar-bottom .add-filled svg {
  width: 22px;
  height: 22px;
}

.cs-sidebar-bottom {
  margin-top: auto;
  padding-top: 16px;
}

.cs-nav-item .cs-side-nav:hover,
.cs-nav-item .cs-side-nav:focus,
.cs-nav-item .cs-side-nav:focus-visible,
.cs-nav-dropdown .cs-side-nav:hover {
  background-color: var(--primary-surface);
  color: var(--neutral-100);
}

.cs-side-nav.active,
.cs-side-nav.active:hover,
.cs-side-nav.cs-nav-drop-item.active,
.cs-dropdown-nav .dropdown-menu a.active {
  background-color: var(--primary-surface);
  color: var(--primary-main) !important;
}

.cs-side-nav:hover svg path,
.cs-side-nav:focus svg path,
.cs-side-nav:focus-visible svg path {
  fill: var(--neutral-100);
}

.cs-side-nav.active svg path,
.cs-side-nav.active:hover svg path,
.cs-side-nav.active:focus svg path,
.cs-side-nav.active:focus-visible svg path,
.cs-dropdown-nav .dropdown-menu a.active svg path {
  fill: var(--primary-main);
}

.cs-nav-dropdown .cs-side-nav .cs-drop-arrow {
  margin-right: 0;
  position: relative;
  top: -1px;
  margin-left: auto;
}

.cs-nav-dropdown.navDropOpen .cs-drop-arrow svg {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  top: 2px;
}

.cs-nav-dropdown .cs-drop-arrow svg {
  -webkit-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s;
}

.cs-dashboard-logo {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto 30px;
  position: relative;
  width: 140px;
}

.cs-full-logo {
  height: auto;
  width: 100%;
}

.cs-nav-dropdown .cs-dropdown-menu {
  position: relative !important;
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  border: none;
  padding-left: 56px;
}

.sidebarClose .cs-nav-dropdown .cs-dropdown-menu {
  padding-left: 0;
}

.cs-nav-drop-item {
  position: relative;
}

.cs-nav-item:last-child {
  margin-bottom: 0;
}

.cs-nav-dropdown .cs-dropdown-menu::before,
.cs-nav-drop-item::before {
  content: '';
  position: absolute;
  background: var(--neutral-30);
}

.cs-nav-dropdown .cs-dropdown-menu::before {
  height: calc(100% - 24px);
  width: 1px;
  top: 5px;
  left: 30px;
}

.sidebarClose .cs-nav-dropdown .cs-dropdown-menu::before,
.sidebarClose .cs-nav-drop-item::before,
.sidebarClose .navDropOpen>.cs-side-nav {
  display: none;
}

.cs-nav-drop-item::before {
  width: 16px;
  height: 1px;
  top: 50%;
  left: -26px;
}

.cs-dropdown-menu li {
  list-style: none;
}

.cs-sidebar-main-widget.is-collapse {
  max-width: 72px;
}

.cs-header.is-expand {
  max-width: calc(100% - 72px);
}

.cs-nav-dropdown .dropdown-toggle {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.dropdown-toggle .cs-drop-arrow {
  margin-left: auto;
}

.cs-notification-count {
  width: 16px;
  height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 8px;
  line-height: 12px;
  font-weight: 400;
  background: var(--primary-main);
  border-radius: 50%;
  color: var(--bs-white);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
}

.cs-side-nav .cs-icon {
  margin-right: 16px;
}

/* Sidebar End */
/* Header Start */
.cs-header-widget {
  width: 100%;
  background-color: var(--neutral-10);
  -webkit-box-shadow: var(--box-shadow-one);
  box-shadow: var(--box-shadow-one);
  padding: 4px 20px;
  position: sticky;
  z-index: 1;
  top: 0;
  height: 100%;
  max-height: 64px;
}

.cs-header .container-fluid {
  display: block;
}

.cs-header .cs-menu-nav {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: inherit;
  flex-wrap: inherit;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cs-content-area {
  width: 100%;
  max-width: calc(100% - 272px);
  margin-left: auto;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.dropdown-menu.cs-pfs-menu {
  position: absolute;
  width: 313px;
  height: auto;
  max-height: 252px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 4px;
  background: var(--neutral-10);
  -webkit-box-shadow: var(--box-shadow-two);
  box-shadow: var(--box-shadow-two);
  margin: 0px;
  border: none;
  overflow-y: auto;
  margin-top: 0px !important;
  z-index: 1;
}

.cs-header-center.cs-navbar-dropdown-btn .dropdown-toggle {
  border: none;
  color: var(--neutral-100);
  padding: 0 24px 0 16px;
}

.cs-header-center .cs-search-bar .cs-form-group {
  margin-bottom: 8px;
}

.cs-navbar-dropdown-btn .dropdown-toggle:active,
.cs-navbar-dropdown-btn .dropdown-toggle:focus {
  background-color: var(--neutral-10);
  color: var(--neutral-100);
}

.cs-navbar-dropdown-btn,
.cs-navbar-dropdown-btn svg {
  margin-left: 16px;
}

.cs-pfs-menu {
  position: absolute;
  width: 313px;
  height: auto;
  max-height: 252px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 4px;
  background: var(--neutral-10);
  -webkit-box-shadow: var(--box-shadow-two);
  box-shadow: var(--box-shadow-two);
  margin: 0px;
  border: none;
  overflow-y: auto;
  margin-top: 0px !important;
  z-index: 1;
}

.cs-pfs-menu .cs-search-text {
  display: block;
  padding: 8px 24px;
  text-transform: uppercase;
}

#my-dashboard span {
  background: transparent;
}

.cs-search-bar {
  padding: 0 16px;
}

.cs-search-result {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  padding: 10px 24px;
  cursor: pointer;
}

.cs-search-result.dropdown-no-record {
  cursor: auto;
}

.cs-search-result.dropdown-no-record:hover {
  background-color: transparent;
  color: var(--neutral-60);
}

.cs-search-result:hover {
  background-color: var(--primary-surface);
  color: var(--neutral-90);
}

.cs-pfs-menu .pfs-item,
.cs-pfs-menu .pfs-item-name {
  padding: 13px 24px 11px;
}

.cs-plan-text {
  color: var(--primary-main);
}

.cs-header-right {
  grid-gap: 24px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
}

.upgrade-navbar p {
  display: inline-block;
  margin-left: 8px;
}

.cs-header-notification,
.cs-header-data-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  grid-gap: 24px;
  border-right: 1px solid var(--neutral-50);
  padding: 8px 24px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-header-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 24px;
}

.cs-header-actions .cs-btn-primary {
  min-width: 74px;
}

.cs-header-notification+.cs-header-data-share {
  padding-left: 0;
  border-left: none;
}

.cs-header-right svg path {
  fill: var(--neutral-80);
}

.cs-header-notification>div {
  position: relative;
}

.cs-header-notification .cs-icon {
  line-height: 24px !important;
}

.cs-notification-badge {
  width: 8px;
  height: 8px;
  display: block;
  position: absolute;
  top: 3px;
  background: var(--primary-main);
  border-radius: 50%;
  right: -1px;
}

.cs-header-actions .cs-plan-text {
  min-width: 105px;
}

.nav-icons>a.dropdown-toggle {
  padding: 0 !important;
  position: relative;
  height: inherit;
}

.cs-header-right .cs-header-dropdown .dropdown-menu {
  left: -435px;
  padding: 0px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  border: 0px;
}

.cs-notification {
  width: 459px;
  height: auto;
  max-height: 358px;
}

.cs-notification .notification,
.cs-notification .notification .notification-img,
.cs-notification .notification span.cs-neutral-100 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-notification .notification span.cs-neutral-100 {
  margin-right: 8px;
}

.cs-notification .title {
  padding: 16px 26px 16px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid var(--neutral-20);
}

.notification-date-time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: right;
}

.notification-item {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.notification-message {
  position: relative;
  padding-right: 5px;
}

.unread .notification-message {
  padding-left: 15px;
}

.unread .notification-item .cs-notification-badge {
  left: 0;
  top: 3px;
  right: initial;
}

.notification-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 26px 8px 16px;
  min-height: 48px;
  border-bottom: 1px solid var(--neutral-20);
}

.notification-link:hover,
.notification-link:active,
.notification-link:focus,
.notification-link:focus-visible {
  background: var(--primary-surface);
}

.cs-notification-area {
  max-height: 170px;
  overflow: hidden auto;
  margin-right: 4px;
}

.notification-link.action {
  background: var(--primary-surface);
}

.action-required .cs-notification-badge {
  background: var(--danger-main);
}

.mark-as {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* ========== Sidebar and Navbar CSS End ========== */

/* ========== Dashboard page CSS start ========== */

.main-section,
.settings-main-section {
  padding: 32px 20px;
  padding-top: 96px;
}

/* Cards Start */

.category-card .card {
  padding: 24px 32px;
  border-radius: 4px;
  background: var(--neutral-10);
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 32px;
  border-bottom: 4px solid var(--primary-main);
}

.cs-dashboard-cards .col-lg-4.col-md-4:last-child .category-card .card {
  cursor: unset;
}

.card-icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 30%;
  max-width: 72px;
  min-width: 72px;
  height: 72px;
}

.cs-dashboard-cards .card .card-icon .cs-icon {
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--primary-surface);
}

.cs-dashboard-cards .row {
  --bs-gutter-x: 2rem;
}

.category-card .card .card-item {
  width: 100%;
  margin-left: 16px;
}

/* Cards End */

/* Tabs Start */
.cs-tabs-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 32px;
}

.cs-company-individual .cs-tabs-flex {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
}

.cs-tabs .cs-stroke-default-tab:first-child {
  border-radius: 4px 0 0 4px;
}

.cs-tabs .cs-stroke-default-tab:last-child {
  border-radius: 0 4px 4px 0;
}

.cs-tabs .cs-stroke-default-tab.cs-stroke-active,
.cs-tabs .cs-stroke-default-tab:focus-visible,
.cs-tabs .cs-stroke-default-tab:hover,
.cs-tabs .cs-stroke-default-tab.cs-stroke-active:active,
.cs-tabs .cs-stroke-default-tab:active {
  border: 1px solid var(--primary-main);
  background: var(--primary-surface) !important;
  color: var(--primary-main) !important;
}

.cs-section-top-bar {
  position: relative;
  margin-bottom: 32px;
}

.cs-tabs .cs-stroke-bottom-tab,
.cs-tabs .cs-stroke-default-tab {
  width: 222px;
  padding: 11px 24px;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-10);
  color: var(--neutral-90);
  border-radius: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  line-height: 16px;
  font-size: 12px;
}

.cs-tabs .cs-stroke-bottom-tab {
  border: 0;
  border-bottom: 2px solid transparent;
}

.cs-tabs .cs-stroke-bottom-active:active,
.cs-tabs .cs-stroke-bottom-active:focus-visible,
.cs-tabs .cs-stroke-bottom-active:hover,
.cs-tabs .cs-stroke-bottom-tab.cs-stroke-bottom-active,
.cs-tabs .cs-stroke-bottom-tab:active,
.cs-tabs .cs-stroke-bottom-tab:focus-visible,
.cs-tabs .cs-stroke-bottom-tab:hover {
  border-color: var(--primary-main) !important;
  color: var(--primary-hover) !important;
  background: var(--neutral-10) !important;
}

.settings-main-section .settings-body .settings-tab-btn {
  padding: 16px 32px 0;
}

.btn-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.btn-row .cs-table-user-action,
.companies-main-section .cs-table-user-action {
  margin-left: 16px;
}

.cs-action-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-action-btn span {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  padding: 6px;
  margin-right: 16px;
  border-radius: 4px;
  background-color: var(--neutral-10);
  -webkit-box-shadow: var(--box-shadow-one);
  box-shadow: var(--box-shadow-one);
  cursor: pointer;
}

.cs-action-btn span.cs-primary {
  background-color: var(--primary-surface);
}

.cs-action-btn span.cs-danger {
  background-color: var(--danger-surface);
}

.cs-action-btn span svg {
  width: 100%;
  height: 100%;
}

span.delete-outlined.cs-delete-btn.cs-disabled span {
  background: transparent !important;
  margin-left: 0 !important;
  cursor: not-allowed !important;
}

/* Tabs End */

/* Individual Table Start */
.cs-individual-table .cs-tbody td:last-child {
  max-width: 45px;
  text-align: right;
}

.cs-individual-table .cs-light-sub-heading-m {
  line-height: 20px;
}

.cs-view-all {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 0;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
}

.cs-delete-btn svg path {
  fill: var(--danger-main);
}

.cs-tbody .cs-delete {
  max-width: 18px;
}

/* ========== Dashboard page CSS end ========== */

/* ========== Individual & Companies page CSS start ========== */

.add-user-action {
  margin-top: 12px;
}

.add-user-action .cs-icon {
  margin-right: 10px;
  line-height: 14px;
}

.cs-md-modal .cs-search-bar {
  padding: 0;
}

.cs-add-row,
.companies-main-section .cs-add-row.for-des,
.companies-main-section .company-add-drop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.financial-advisor-top-bar .cs-action-btn {
  position: absolute;
  top: 50%;
  left: calc(100% - 350px);
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.client-top-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cs-section-top-bar .cs-search-bar {
  max-width: 450px;
  width: 100%;
}

.individual-main-section .lg-btn,
.companies-main-section .lg-btn {
  max-width: 172px;
  padding: 9px 16px;
}

.cs-new-user-modal h5 {
  margin-bottom: 16px;
}

.associate-add-more {
  margin-top: 16px;
}

.cs-create-company {
  margin-top: 18px;
}

.cs-create-company .cs-form-group {
  margin-bottom: 4px;
  padding-inline: 16px;
}

.cs-create-company .cs-form-dropdown {
  margin-bottom: 0;
}

.partners-details-table {
  margin-top: 18px;
}

.cs-create-company .cs-form-group .input-field-icon {
  right: 32px;
}

.partners-details-table .cs-table th,
.partners-details-table .cs-table td {
  padding-block: 7px;
}

.companies-table tr {
  cursor: pointer;
}

.companies-table tr .accordion tr {
  cursor: auto;
}

.companies-table tr .accordion .accordion-item,
.companies-table tr .accordion .accordion-item .cs-table {
  border: 0;
}

.companies-table td[colspan='5'],
.companies-main-section td[colspan='6'] {
  position: relative;
  padding: 0 24px 8px;

  top: -1px;
}

.companies-table tr .accordion .accordion-item .cs-table th {
  background: var(--neutral-20);
  border: 0;
}

.companies-table tr .accordion .accordion-item .cs-table th:first-child {
  border-radius: 4px 0 0 4px;
}

.companies-table tr .accordion .accordion-item .cs-table th:last-child {
  border-radius: 0 4px 4px 0;
}

.company-popup-remove {
  text-align: end;
}

.company-popup-remove .btn-primary {
  margin-left: auto;
  padding: 0;
}

.company-popup-remove .btn-primary span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 4px;
  width: 16px;
  height: 16px;
}

.company-popup-remove .btn-primary span svg {
  width: 100%;
  height: 100%;
}

.client-top-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cs-section-top-bar .cs-search-bar {
  max-width: 450px;
  width: 100%;
}

.individual-main-section .lg-btn {
  max-width: 172px;
}

.cs-new-user-modal h5 {
  margin-bottom: 16px;
}

.choose-user-modal {
  position: relative;
}

.choose-user-modal .cs-search-bar {
  margin-bottom: 20px;
}

.choose-user-modal span.cursor-pointer.cs-neutral-80 {
  position: absolute;
  top: 50%;
  right: 16px;
  margin-top: 9px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.choose-user-modal span.form-error-msg {
  bottom: -20px;
}

.cs-user-td,
.cs-md-modal .cs-table-widget {
  position: relative;
  max-height: 182px;
  margin-top: 10px;
  padding-right: 10px;
  margin-right: -10px;
  overflow: auto;
  width: auto;
}

.cs-user-td .row {
  border: 1px solid var(--neutral-50);
  border-radius: 4px;
  margin: 0;
  margin-bottom: 16px;
  padding-block: 15px;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  cursor: pointer;
}

.cs-user-td .row:last-child {
  margin-bottom: 0;
}

.cs-user-td .row:hover,
.cs-user-td .table-active {
  background-color: var(--primary-surface);
  border-color: var(--primary-main);
}

.individual-table .cs-export-row,
.companies-table .cs-export-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cs-export-row .export-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-export-row .export-btn svg {
  margin-right: 6px;
}

.cs-delete-individual .cs-modal-btn {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.cs-delete-individual .cs-d-flex-btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cs-delete-individual .cs-d-flex-btn .cs-btn-secondary {
  max-width: 276px;
  width: 100%;
}

.cs-delete-individual .cs-d-flex-btn span {
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.cs-delete-individual .cs-modal-btn .cs-divider {
  width: 233px;
  margin: 8px auto;
}

.cs-partner-details {
  margin-bottom: 1rem;
}

.company-details-modal td {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.company-input {
  position: relative;
}

.company-input span.form-error-msg {
  margin-top: -24px;
}

.company-input {
  position: relative;
}

.company-input span.form-error-msg {
  margin-top: -24px;
}

.view-and-manage-container.add-more-btn {
  position: relative;
}

.associate-add-more.cs-disabled .cs-form-check-box .form-check-label span {
  cursor: not-allowed !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.companies-main-section .companies-table .accordion .accordion-item .cs-tbody tr td {
  border-bottom: 1px solid var(--neutral-50);
  border-radius: 0;
}

.companies-main-section .companies-table .accordion .accordion-item .cs-tbody tr:last-child td {
  border-bottom: 0;
}

.cs-common-modal-length .modal-body {
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 0;
}

.cs-common-modal-length .modal-footer {
  padding-top: 24px;
}

.cs-repeated-scroller,
.associate-add-more .table-responsive {
  position: relative;
  max-height: 250px;
  padding-right: 10px;
  margin-right: -10px;
  overflow: hidden;
  overflow-y: auto;
}

.associate-add-more .table-responsive {
  max-height: 140px;
}

.view-and-manage-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 6px;
}

.view-and-manage-header .dropdown-toggle {
  padding: 0;
  height: auto;
  margin: 0;
}

.view-and-manage-header .cs-dropdown-nav .dropdown-menu {
  max-width: 85px;
  width: 100%;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  left: -66px !important;
}

.view-and-manage-header .cs-dropdown-nav .dropdown-menu a {
  padding-block: 12px;
}

.view-and-manage-container .cs-btn-icon-tertiary,
.view-manage-user-modal .cs-btn-icon-tertiary {
  padding-top: 18px;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
}

/* ========== Individual & Companies page CSS end ========== */

/* ========== Setting page CSS start ========== */

.settings-tab-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  background-color: var(--neutral-10);
  border-bottom: 1px solid var(--neutral-30);
  border-radius: 4px 4px 0 0;
}

.cs-breadcrumb .cs-icon {
  padding: 0 4px;
}

.cs-tbody tr:last-child td {
  border: none;
}

.cs-table-widget .cs-table {
  margin-bottom: 0;
}

.cs-modal-multi-button .modal-footer button+button {
  margin-left: 16px;
}

.cs-form-group .form-control:disabled::-webkit-input-placeholder {
  color: var(--neutral-60);
}

.cs-form-group .form-control:disabled::-moz-placeholder {
  color: var(--neutral-60);
}

.cs-form-group .form-control:disabled:-ms-input-placeholder {
  color: var(--neutral-60);
}

.cs-form-group .form-control:disabled::-ms-input-placeholder {
  color: var(--neutral-60);
}

.cs-form-group .form-control:disabled::placeholder {
  color: var(--neutral-60);
}

.form-check-input:checked {
  background-color: var(--primary-main);
  border-color: var(--primary-main);
  cursor: pointer;
}

.form-check-input:focus {
  -webkit-box-shadow: 0 0 0 0.25rem var(--primary-surface);
  box-shadow: 0 0 0 0.25rem var(--primary-surface);
}

.settings-body>.container-fluid {
  padding: 0 32px;
}

.setting-cs-table-container,
.cs-setting-page {
  margin-top: 32px;
}

.cs-setting-page {
  margin-bottom: 32px;
}

/* Team Collaboration Start */
.setting-cs-table-container .chevron-right-outlined {
  position: relative;
  margin-left: 13px;
  bottom: 1px;
}

.setting-cs-table-container td:last-child {
  width: 110px;
}

.cs-add-individual-action {
  margin-top: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
}

.team-collaboration-sec button.lg-btn {
  margin-left: auto;
  max-width: 174px;
}

.team-collaboration-sec .cs-form-group {
  margin-bottom: 0;
}

.cs-setting-page .cs-btn-icon-primary,
.cs-audit-logs-sec .cs-search-bar {
  margin-bottom: 32px;
}

.cs-add-individual-action button span {
  margin-right: 8px;
}

.cs-audit-logs-sec .cs-search-bar {
  padding: 0;
  max-width: 350px;
  margin-left: auto;
}

.cs-audit-logs-sec .cs-tbody td:last-child {
  width: 300px !important;
  min-width: 300px !important;
  white-space: break-spaces;
}


.cs-pagination-select .css-1hb7zxy-IndicatorsContainer::before,
.cs-pagination-select .css-1wy0on6::before {
  width: 14px !important;
  height: 14px !important;
  right: 0 !important;
  margin-inline: -8px;
}

.cs-common-add-dropdown .dropdown .dropdown-menu.show .dropdown-item.cs-pagination-select {
  background: var(--primary-surface);
  color: var(--primary-main) !important;
}

.individual-table .cs-table .cs-form-group,
.companies-table .cs-table .cs-form-group,
.cs-new-user-modal .cs-table .cs-form-group {
  margin-bottom: 0 !important;
  width: 16px;
}

.cs-table .cs-form-group .css-13cymwt-control {
  cursor: pointer;
}

.cs-table .cs-dropdown {
  max-width: 248px;
}

.cs-modal-text {
  margin-top: 24px;
  margin-bottom: 10px;
}

.cs-delete-modal .delete-filled {
  display: block;
  margin-top: 12px;
}

.team-collaboration-table .text-end {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.team-collaboration-table .text-end span:last-child {
  margin-left: 8px;
}

.team-collaboration-table.cs-table .cs-form-group .css-13cymwt-control,
.team-collaboration-table.cs-table .cs-form-group .css-t3ipsp-control {
  min-height: 30px;
}

.collaboration-action-drop .dropdown .dropdown-toggle {
  border: 0;
  padding: 0;
  height: auto;
}

.collaboration-action-drop .cs-form-dropdown {
  margin: 0;
}

.collaboration-action-drop .dropdown-item.active,
.collaboration-action-drop .dropdown-item:active {
  background-color: var(--primary-surface);
  color: var(--primary-main);
}

.team-collaboration-sec .collaboration-permission-dropdown .cs-form-dropdown {
  margin: 0;
}

.team-collaboration-sec .collaboration-permission-dropdown .dropdown .dropdown-toggle {
  padding: 7px 16px;
  height: 30px;
  gap: 8px;
}

.team-collaboration-sec .collaboration-permission-dropdown .dropdown-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 8px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 9px 16px;
}

.team-collaboration-sec .collaboration-permission-dropdown .cs-scroller {
  max-height: 120px;
}

/* Team Collaboration End */

/* Setting File Encryption Start */
.cs-text-note>div {
  margin-bottom: 8px;
}

.cs-setting-form {
  margin-top: 24px;
}

.cs-setting-form form .form-control {
  background: var(--neutral-20);
  color: var(--neutral-70);
}

.cs-setting-form .cs-btn-primary {
  margin-top: 42px;
}

.cs-setting-form .row>div form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100%;
}

.cs-form-group .cs-neutral-60 {
  color: var(--neutral-60);
}

.auth-right .cs-regular-h5 {
  margin-bottom: 24px;
}

.file-encryption-row .cs-form-group .form-control {
  overflow: hidden;
  padding-right: 38px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-encryption-row {
  padding-right: calc(100% - 59vw);
  position: relative;
}

.cs-password-input .input-field-icon {
  margin-top: 11px;
}

/* Setting File Encryption End */

/* Setting MFA Setup Start */
.settings-main-section .mfa-setup-page .mfa-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  grid-gap: 32px;
}

.settings-main-section .mfa-setup-page .settings-mfa-card,
.settings-main-section .mfa-setup-page .settings-mfa-card .mfa-icon-text {
  background: var(--neutral-10);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.settings-main-section .mfa-setup-page .settings-mfa-card {
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  padding: 16px 24px;
  width: 100%;
  max-width: 460px;
}

.settings-main-section .mfa-setup-page .settings-mfa-card .mfa-content-text {
  margin: 16px 0;
  text-align: center;
}

/* Setting MFA Setup End */

/* User Account Details Start */
.cs-profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-profile-details {
  margin-left: 24px;
}

.cs-profile-update {
  position: relative;
}

.edit-image-filled {
  background: var(--primary-main);
  border-radius: 50px;
  width: 28px;
  height: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  bottom: 4px;
  right: 4px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-profile-details button {
  margin-top: 8px;
  max-width: 132px;
}

.cs-profile-update img {
  /* border-radius: 50%; */
}

.cs-profile-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 16px;
}

.cs-profile-form {
  max-width: 1031px;
}

.cs-profile-form>form>.row {
  --bs-gutter-x: 32px;
}

.cs-profile-details .verified-filled {
  margin-left: 6px;
  position: relative;
  top: -2px;
}

/* User Account Details End */
/* Custom Branding Start */
.settings-main-section .cs-branding-accordion.cs-accordion .accordion-item:not(.cs-branding-accordion.cs-accordion .accordion-item:last-child) {
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.settings-main-section .branding-container .row {
  height: 100%;
}

.settings-main-section .branding-container {
  padding-inline: 0;
  height: 73vh;
  overflow: hidden;
}

.settings-main-section .branding-container .branding-left {
  height: 100%;
  max-height: 66vh;
}

.settings-main-section .branding-right {
  height: 100%;
}

.settings-main-section .choose-temp-design {
  height: 45vh;
}

.settings-main-section .template-bg {
  min-height: auto;
  height: 45vh;
}

.settings-main-section .template-bg img {
  height: 100%;
}

.settings-main-section .template3-section .row .col,
.settings-main-section .template3-section .template-left-side,
.settings-main-section .template3-section .template-bg,
.settings-main-section .template3-section .template-bg img {
  height: 100%;
  min-height: auto;
}

.settings-main-section .template2-section .template-right-section {
  max-width: 300px;
  min-height: 40vh;
}

.settings-main-section .template3-section {
  padding: 26px 40px;
}

.settings-main-section .template4-section .row:first-child .col:not(.col:first-child) {
  height: 40vh;
  margin-left: 1vw;
}

/* Custom Branding End */

/* ========== Setting page CSS end ========== */
/* ========== 404 page start ========== */
.error-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  height: 100vh;
}

.error-image {
  max-width: 100%;
  margin: 50px 0 30px;
}

.error-image img {
  width: 100%;
  height: auto;
}

.error-content {
  max-width: 400px;
}

.error-content h1 {
  margin-bottom: 10px;
}

.error-content button {
  margin: 36px auto 0;
}

/* ========== 404 page end ========== */


/* ========== Profile navigation Start ========== */
.favicon-small .advanced-cropper-boundary.advanced-cropper__boundary,
.favicon-Avatar .advanced-cropper-boundary.advanced-cropper__boundary {
  height: 458px;
}

.advanced-cropper-boundary.advanced-cropper__boundary {
  height: 260px;
}

.advanced-cropper__background-wrapper {
  background-image: url(../images/back-image.png);
}

.profile-navigation {
  display: flex;
  position: relative;
  align-items: center;
  padding: 16px 0 6px;
  z-index: 2;
  justify-content: space-between;
  background-color: var(--neutral-10);

}

.profile-navigation .cs-icon {
  position: relative;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  min-height: 24px;
  border: 0;
  outline: none !important;
  background: var(--primary-main);
  border-radius: 3px;
}
.profile-navigation .cs-icon.cs-disabled{
background: var(--neutral-30) !important;

}
.profile-navigation .cs-icon svg {
  width: 14px;
  height: 14px;
}

input.form-range,
.form-range:focus {
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
}

.form-range {
  -webkit-appearance: none;
  width: 84%;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  animate: 0.2s;
  background: var(--neutral-40);
  border-radius: 30px;
}

.form-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: var(--primary-main);
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  transform: translateY(calc(-50% + 7px));
}

.form-range:focus::-webkit-slider-thumb {
  background: var(--primary-pressed);
}

/* ========== Profile navigation End ========== */

/* ========== Billing & Subscriptions Start ========== */
.billing-subscription-tab {
  padding-block: 32px;
}

.billing-subscription-tab .container-fluid {
  padding: 0;
}

.billing-subscription-tab .cs-setting-page.cs-audit-logs-sec {
  margin-top: 0;
}

.billing-box {
  position: relative;
  padding: 24px;
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-10);
}

.cs-current-billing {
  background: var(--primary-surface);
}

.cs-subscription-section .card-plan-amount {
  align-items: center;
  display: flex;
  text-align: center;
}

.billing-box .subscription-plan-cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--neutral-50);
}

.card-specification ul {
  padding: 0;
}

.cs-subscription-section .card-specification li {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 16px;
  padding-left: 30px;
  justify-content: space-between;
}

.cs-subscription-section .card-specification li:last-child {
  margin-bottom: 0;
}

.cs-subscription-section ul li:before {
  content: '';
  display: inline-block;
  height: 12px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 20px;
  -webkit-mask-image: url(../images/icons/check-icon-color.svg);
  -webkit-mask-size: cover;
  -webkit-mask-position: 50%;
  -webkit-mask-repeat: no-repeat;
  background-color: var(--primary-main);
}

.billing-box .lg-btn {
  margin: 32px auto 0;
}

.add-ons-row {
  position: relative;
  padding-block: 32px;
}

.add-ons-row .col-lg-12 {
  margin-bottom: 16px;
}

.billing-box.add-ons-box {
  text-align: center;
  height: 100%;
}

.add-ons-icon {
  position: relative;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px solid var(--primary-border);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-surface);
  margin-bottom: 16px;
}

.card-plan-amount h2.cs-regular-h3.cs-neutral-100 {
  margin-top: -2px;
}

.billing-box.add-ons-box .lg-btn {
  margin-block: 14px 12px;
}

.billing-box.add-ons-box .card-plan-amount {
  justify-content: center;
}

.billing-box.add-ons-box.accreditation-bill,
.billing-box.add-ons-box.users-licenses {
  padding-bottom: 10px;
}

.billing-box.add-ons-box.accreditation-bill .lg-btn,
.billing-box.add-ons-box.users-licenses .lg-btn {
  margin-block: 16px 8px;
}

.accreditation-bill .add-ons-icon {
  border: 0;
  background: none;
  border-radius: 0;
}

.add-ons-table .cs-thead th {
  font-weight: 500;
}

.add-ons-table .text-end {
  padding-right: max(30px, 8vw);
}

.billing-box .cs-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding-right: max(16px, 2vw);
}

.billing-box .cs-flex .cs-semi-bold-h1 {
  margin-top: 2px;
}

.cs-datafield {
  position: relative;
}

.cs-datafield:before {
  content: attr(data-title);
  display: block;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translate(0, -50%);
  margin-top: 11px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: var(--primary-main);
  z-index: 1;
  pointer-events: none;
}

.cs-datafield .form-control {
  padding-right: 50px;
}

.cs-subcription-table {
  border: 1px solid var(--neutral-50);
  border-radius: 4px;
  padding: 16px;
}

.cs-subcription-table h3.cs-regular-h3 {
  margin-bottom: 14px;
}

.cs-subcription-table table,
.cs-subcription-table table> :not(caption)>*>* {
  border: 0 !important;
  border-radius: 0 !important;
}

.cs-subcription-table .cs-table th {
  background: transparent;
  color: var(--neutral-70);
}

.cs-subcription-table .cs-table td {
  padding-block: 12px !important;
  font-weight: 400 !important;
  color: var(--neutral-100) !important;
}

.cs-subcription-table .cs-table th:first-child,
.cs-subcription-table .cs-table tr td:first-child {
  padding-left: 0;
}

.cs-subcription-table .cs-table th:last-child,
.cs-subcription-table .cs-table tr td:last-child {
  padding-right: 0;
}

.cs-subcription-table table .cs-tbody tr:last-child td {
  border-bottom: 1px solid var(--neutral-40) !important;
  padding-bottom: 24px !important;
}

.cs-subcription-table table .cs-tfoot tr td {
  padding-block: 24px 0 !important;
  font-size: 14px;
  font-weight: 600 !important;
  line-height: 20px;
}

.cs-storageplan {
  text-align: center;
}

.cs-storageplan p {
  margin-block: 20px 8px;
  display: block;
}

.cs-storage {
  margin-bottom: 24px;
}

.cs-storage .cs-progress-bar {
  height: 8px;
  margin-block: 0;
  width: 100%;
}

.storage-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3px;
}

.cs-storage .cs-progress-bar .progress {
  height: 100%;
}

.cs-progress-bar .progress-bar {
  background: var(--primary-main);
  border-radius: 4px;
}

.storage-credit {
  margin-bottom: 10px;
}

.cs-search-bar~.cs-common-no-record {
  margin-block: 24px 0;
}

.cs-credit-tble {
  margin-top: 24px;
}

.cs-datafield input[type='number']::-webkit-inner-spin-button,
.cs-datafield input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.billing-box span.cs-neutral-100 {
  margin-top: 24px;
}

.upgrade-plan ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.upgrade-plan ul li:last-child {
  margin-left: 18px;
}

.upgrade-plan ul li svg {
  margin-right: 8px;
}

.upgrade-plan .cs-storageplan {
  margin-bottom: 14px;
}

.payment-method-modal p.cs-regular-body-text-m.cs-neutral-100 {
  margin-bottom: 4px;
}

.payment-method-modal .cs-scroller {
  max-height: 422px;
  margin-bottom: 16px;
  margin-right: -8px;
  padding-right: 4px;
}

.change-card-image .cs-card-logo img,
.payment-cards .credit-text .cs-card-logo img {
  width: 100%;
  max-width: 46px;
  /* height: 26px; */
  height: 28px;
  object-fit: contain;
  margin: 0 !important;
}

.payment-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 32px;
}

.payment-cards .text-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.payment-cards .text-button button {
  padding: 0 !important;
}

.payment-method {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-item-align: normal;
  -ms-grid-row-align: normal;
  align-self: normal;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 4px;
}

.payment-cards .credit-payment-card {
  margin-top: 16px;
  width: 100%;
}

.payment-cards .credit-payment-card .credit-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 24px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-10);
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.payment-cards .credit-payment-card:hover .credit-box {
  background: var(--primary-surface);
}

.payment-cards .credit-payment-card .credit-box .credit-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.payment-cards .credit-payment-card .credit-box .credit-text p.default-regular-body-text-xl.cs-neutral-80 {
  margin-bottom: 8px;
}

.payment-cards .credit-box span.delete-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  background: var(--danger-surface);
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.payment-cards .credit-box span.delete-action:hover {
  background: var(--danger-hover);
}

.payment-cards .credit-box span.delete-action:hover svg path {
  fill: var(--neutral-10);
}

.payment-cards .credit-payment-card .credit-box-active {
  border: 1px solid var(--primary-main);
  background-color: var(--primary-surface);
}

.billing-subscription-page .payment-cards p.default-light-body-text-s.cs-neutral-70 {
  margin: 8px 0 16px 0;
}

.payment-cards .credit-payment-card {
  margin-top: 8px;
}

.payment-cards .credit-payment-card .credit-box {
  padding: 12px 16px;
}

.add-payment-method-button button.default-regular-h5.cs-btn-icon-tertiary {
  padding: 0 !important;
}

.qbo-disconnect .cs-btn-icon-tertiary {
  padding-right: 0 !important;
}

.cs-cancelling-popup .cs-btn-icon-tertiary {
  position: relative;
  display: inline-block;
  width: auto;
  padding: 0;
  cursor: pointer;
}

.company-add-popup .modal-body button.cs-btn-icon-tertiary {
  padding-left: 0 !important;
}

.financial-initial-modal .cs-btn-icon-tertiary,
.financial-initial-modal .cs-btn-icon-tertiary span.cs-primary {
  padding-left: 0 !important;
  margin: 0;
}

.financial-initial-modal .cs-btn-icon-tertiary span svg {
  margin-right: 8px;
}

.credit-change-method {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 24px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-20);
  cursor: pointer;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
  height: 100px;
}

.settings-main-section .payment-cards .credit-change-method {
  margin-bottom: 0;
}

.credit-change-method.cs-disabled {
  background: var(--neutral-40) !important;
  border-color: var(--neutral-40) !important;
  cursor: not-allowed !important;
}

.credit-change-method.cs-disabled .cs-card-logo {
  opacity: 0.5;
}

.cs-scroller .credit-change-method:last-child {
  margin-bottom: 0;
}

.credit-change-method,
.bank-details {
  padding: 16px 24px;
  width: 100%;
}

.change-method-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--primary-main);
  background: var(--primary-surface);
  cursor: pointer;
}

.cs-table-widget.cs-purchase-table {
  margin-top: 24px;
  max-height: 181px;
  padding-right: 6px;
  margin-right: -10px;
}

.payment-method-modal .cs-btn-icon-tertiary {
  padding: 0 !important;
}

/* .stripe-form .cs-form-group {
  margin-bottom: 0;
}
*/

.change-credit-text p {
  margin-bottom: 8px !important;
}

.change-card-image {
  align-items: center;
  display: flex;
}

.cs-card-logo {
  align-items: center;
  background: var(--neutral-10);
  border: 1px solid var(--neutral-30);
  border-radius: 6px;
  display: inline-flex;
  height: 36px;
  justify-content: center;
  margin-right: 16px;
  margin-top: -6px;
  padding: 6px;
  width: 52px;
}

.change-card-delete {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 6px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  background: var(--danger-surface);
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.change-card-delete:hover {
  background: var(--danger-hover);
}

.change-card-delete:hover svg path {
  fill: var(--neutral-10);
}

.cs-subscription-section .billing-box-skeleton ul li:before {
  display: none;
}

.cs-subscription-section .billing-box-skeleton .card-specification li {
  padding: 0;
}

.billing-box-skeleton .billing-box,
.billing-box-skeleton .credit-change-method {
  background: var(--neutral-30);
}

.billing-box-skeleton .credit-change-method .cs-card-logo {
  background: var(--neutral-40);
}

.billing-box-skeleton .card-specification .Skeleton-text {
  display: block;
  width: 44%;
}

.billing-box-skeleton .card-specification .card-plan-amount {
  display: block;
  width: 20%;
}

.cs-stripe-spinner {
  position: absolute !important;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9;
}

span.sorting-before {
  display: inline-block;
  margin-left: 4px;
  transform: rotateX(180deg);
  transition: 0.3s all;
}

span.sorting-after {
  display: inline-block;
  margin-left: 4px;
  transform: rotateX(0deg);
  transition: 0.3s all;
}

/* ========== Billing & Subscriptions End ========== */