.cs-main-layout.skeleton-main-layout {
  display: block;
}

.cs-nav-skeleton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 40px;
}

.skeleton-navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 150px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.skeleton-navbar .item-1 {
  margin-left: 16px;
}

.skeleton-navbar .item-2 {
  margin-left: 50px;
}

.skeleton-menu {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.skeleton-menu .menu-1 {
  width: 144px;
}

.skeleton-menu .menu-2 {
  width: 67px;
  margin-left: 57px;
}

.skeleton-menu .menu-3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 85px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 64px;
}

.skeleton-menu .menu-3 .menu-item {
  width: 24px;
}

.skeleton-menu .menu-4 {
  margin-left: 20px;
}

.skeleton-menu .menu-4.for-mob {
  margin-left: 20px;
  margin-right: 10px;
}

.skeleton-main-nav-item {
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  margin-bottom: 8px;
  padding: 8px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.skeleton-menu-icon {
  position: relative;
  margin-right: 16px;
}

.sidebar-add-account {
  margin-top: auto;
}

.cs-main-sidebar {
  width: 272px;
  min-height: 100%;
  position: fixed;
  border-right: 1px solid var(--neutral-30);
  background: var(--neutral-10);
  padding: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 3;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.cs-main-sidebar .cs-logo {
  text-align: center;
  display: block;
  margin-top: 4px;
  margin-bottom: 44px;
}

/* =================================*/
/* end - [cs-section-top-bar]   */
/* ================================ */
/* ----------------------------------------------Cards------------------------------------------------ */

.skeleton-cards .card {
  border: none !important;
  background-color: var(--neutral-30) !important;
}

.skeleton-table > :not(caption) > * > * {
  background-color: var(--neutral-30) !important;
}

.cs-header.cs-skeleton-header {
  background-color: var(--neutral-10);
  -webkit-box-shadow: var(--box-shadow-one);
  box-shadow: var(--box-shadow-one);
  width: 100%;
  max-width: calc(100% - 272px);
  position: fixed;
  padding: 12px 16px;
  z-index: 2;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  min-height: 64px;
}

.skeleton-manage-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
  margin-top: 24px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.skeleton-tabs-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-main-layout.cs-nav-toggled:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--neutral-100);
  z-index: 3;
  opacity: 0.3;
}

.cs-main-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.cs-main-layout .cs-context {
  padding: 96px 16px 32px 16px;
}

.wm-skeleton-margin {
  margin-bottom: 32px;
}

.wm-skeleton-tabs-container {
  margin-top: 0px;
}

.skeleton-tabs {
  height: 40px;
}

.skeleton-tabs-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.skeleton-manage-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
  margin-top: 24px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.skeleton-audit-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
  margin-top: 24px;
}

.skeleton-manage-audit-1 {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.skeleton-manage-audit-2 {
  margin-left: auto;
}

.skeleton-table > :not(caption) > * > * {
  background-color: var(--neutral-30) !important;
}

.skeleton-breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.skeleton-breadcrumb .skeleton-breadcrumb-1 {
  margin-right: 4px;
}

.skeleton-breadcrumb .skeleton-breadcrumb-2 {
  margin-right: 4px;
}

.skeleton-account-box {
  width: 67%;
  padding: 32px 0px;
}

.skeleton-profile {
  margin-right: 24px;
}

.skeleton-personal-information {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 16px;
}

.skeleton-change-pass {
  margin-top: 10px;
}

.skeleton-account-tabs {
  padding: 16px 32px 0px 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.skeleton-account-tabs .skeleton-account-tab {
  padding: 10px 0px;
  width: 15%;
  text-align: center;
}

.skeleton-account-box .personal-inform-form .skeleton-forms {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 24px;
}

.skeleton-account-box .personal-inform-form .row:last-child .skeleton-forms {
  margin-bottom: 0;
}

.skeleton-account-box .personal-inform-form .skeleton-forms .skeleton-label {
  width: 6vw;
}

.skeleton-file-encryption-heading {
  width: 20%;
}

.skeleton-file-encryption-para {
  width: 63%;
}

.skeleton-file-encryption-input {
  width: 100%;
}

.skeleton-audit-log-search-tab {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.skeleton-audit-container .for-mob {
  width: 100%;
}

.skeleton-account-box .profile {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.skeleton-branding-item {
  display: flex;
  justify-content: space-between;
}

.cs-branding-accordion .accordion-body .skeleton-continue {
  margin-left: auto;
  margin-right: 0;
}

.cs-branding-accordion .accordion-body .skeleton-continue span {
  display: flex;
  justify-content: end;
}

@media (max-width: 1499px) {
  .for-des.skeleton-common-table .skeleton-table tr th:last-child,
  .for-des.skeleton-common-table .skeleton-table tr th:nth-child(4),
  .for-des.skeleton-common-table .skeleton-table tr td:last-child,
  .for-des.skeleton-common-table .skeleton-table tr td:nth-child(4) {
    display: none !important;
  }

  .skeleton-table {
    overflow: hidden;
  }
}
@media (max-width: 992px) {
  .skeleton-account-box .profile {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .skeleton-account-box {
    width: 100%;
  }

  .skeleton-account-box .profile h2.default-semi-bold-h2.cs-neutral-100 {
    text-align: center;
  }

  .skeleton-profile {
    margin: 0 0 10px;
  }
}
@media (max-width: 360px) {
  .skeleton-menu .menu-3 div:first-child {
    display: none;
  }

  .skeleton-menu .menu-3 {
    margin-left: 0;
  }

  .skeleton-navbar .item-2 {
    margin-left: 10px;
  }

  .skeleton-navbar .item-1 {
    margin: 0;
  }
}
