/* ==========  START - Font Family ========== */
@font-face {
  font-family: 'Readex Pro';
  src: url('../fonts/ReadexPro-Bold.eot');
  src:
    url('../fonts/ReadexPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ReadexPro-Bold.woff2') format('woff2'),
    url('../fonts/ReadexPro-Bold.woff') format('woff'),
    url('../fonts/ReadexPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Readex Pro';
  src: url('../fonts/ReadexPro-Light.eot');
  src:
    url('../fonts/ReadexPro-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ReadexPro-Light.woff2') format('woff2'),
    url('../fonts/ReadexPro-Light.woff') format('woff'),
    url('../fonts/ReadexPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Readex Pro';
  src: url('../fonts/ReadexPro-ExtraLight.eot');
  src:
    url('../fonts/ReadexPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ReadexPro-ExtraLight.woff2') format('woff2'),
    url('../fonts/ReadexPro-ExtraLight.woff') format('woff'),
    url('../fonts/ReadexPro-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Readex Pro';
  src: url('../fonts/ReadexPro-Regular.eot');
  src:
    url('../fonts/ReadexPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ReadexPro-Regular.woff2') format('woff2'),
    url('../fonts/ReadexPro-Regular.woff') format('woff'),
    url('../fonts/ReadexPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Readex Pro';
  src: url('../fonts/ReadexPro-Medium.eot');
  src:
    url('../fonts/ReadexPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ReadexPro-Medium.woff2') format('woff2'),
    url('../fonts/ReadexPro-Medium.woff') format('woff'),
    url('../fonts/ReadexPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Readex Pro';
  src: url('../fonts/ReadexPro-SemiBold.eot');
  src:
    url('../fonts/ReadexPro-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ReadexPro-SemiBold.woff2') format('woff2'),
    url('../fonts/ReadexPro-SemiBold.woff') format('woff'),
    url('../fonts/ReadexPro-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* ========== END - Font Family ========== */

/* ========== Dynamic color palettes start ========== */
:root {
  --primary-main: #0024d4;
  --primary-surface: color-mix(in srgb, var(--primary-main) 6%, var(--neutral-10) 50%);
  --primary-border: color-mix(in srgb, var(--primary-main) 30%, var(--neutral-10));
  --primary-hover: color-mix(in srgb, var(--primary-main) 80%, var(--neutral-100));
  --primary-pressed: color-mix(in srgb, var(--primary-main) 60%, var(--neutral-100));
  --primary-focus: color-mix(in srgb, var(--primary-main) 20%, var(--neutral-10));
  --primary-light: color-mix(in srgb, var(--primary-main) 30%, var(--neutral-10) 70%);
  --primary-canvas-surface: #f8f9fb;
}

/* ========== Dynamic color palettes end ========== */

/* ========== WebApp variables Start ========== */
:root {
  --neutral-10: #ffffff;
  --neutral-20: #f5f5f5;
  --neutral-30: #ededed;
  --neutral-40: #e0e0e0;
  --neutral-50: #c2c2c2;
  --neutral-60: #9e9e9e;
  --neutral-70: #757575;
  --neutral-80: #616161;
  --neutral-90: #424242;
  --neutral-100: #0a0a0a;
  --asset-main: #007f48;
  --asset-surface: #cce5da;
  --asset-chart: #3d9c73;
  --asset-opacity: #ebf5f1;
  --liability-main: #e53535;
  --liability-surface: #fad7d7;
  --liability-chart: #e85b5b;
  --liability-opacity: #fdefef;
  --font-color-sub-title: #2f2f2f;
  --danger-main: #e00000;
  --danger-surface: #fff4f4;
  --danger-border: #f1aaaa;
  --danger-hover: #b10000;
  --danger-pressed: #840b0b;
  --danger-focus: #fbd9d9;
  --warning-main: #ea780e;
  --warning-surface: #fbf1e7;
  --warning-border: #f2c69d;
  --warning-hover: #c3640c;
  --warning-pressed: #753c07;
  --warning-focus: #fbe4cf;
  --success-main: #008243;
  --success-surface: #d5eae0;
  --success-border: #93c6ad;
  --success-hover: #005e30;
  --success-pressed: #004121;
  --success-focus: #bfdfd0;
  --info-main: #0b5cd7;
  --info-surface: #e5efff;
  --info-border: #a3c0ed;
  --info-hover: #094cb3;
  --info-pressed: #052e6b;
  --info-focus: #cedef7;
  --cs-font: 'Readex Pro';
  --box-shadow-one: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  --box-shadow-two: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
}

/* ========== WebApp variables End ========== */