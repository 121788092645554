.branding-section {
  position: relative;
  background: var(--neutral-10);
}

.branding-container .branding-left {
  position: relative;
  padding-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(100vh - 120px);
  overflow-x: hidden;
}

.branding-left .cs-btn-group {
  margin-top: auto;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.branding-section .container-fluid {
  padding-inline: 0;
}

.branding-title {
  position: relative;
  padding: 16px;
  border-bottom: 1px solid var(--neutral-20);
}

.branding-container {
  position: relative;
  padding: 32px;
  height: calc(100vh - 57px);
}

.accordion-header .accordion-button>span {
  border-bottom: 2px solid transparent;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.settings-body span.cs-regular-h3.cs-neutral-100 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.cs-accordion-active .accordion-header .accordion-button>span {
  border-color: var(--primary-main);
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.cs-branding-accordion .accordion-body {
  padding-block: 8px 0;
}

.accordion-collapse.collapse.show h3.cs-regular-body-text-l {
  margin-block: 10px 6px;
}

.cs-branding-accordion .accordion-body>p,
.cs-branding-accordion .accordion-body .cs-form-group {
  margin-bottom: 16px;
}

.cs-branding-accordion .accordion-body .cs-btn-primary {
  margin-left: auto;
  margin-right: 0;
}

.cs-choose-file .cs-common-spinner {
  width: 14px;
  height: 14px;
  position: absolute;
  right: 10px;
}

.cs-choose-file .cs-common-spinner svg path {
  fill: var(--neutral-60) !important;
}

.cs-branding-accordion .accordion:not(.accordion:last-child) {
  border-bottom: 1px solid var(--neutral-40);
  padding-bottom: 16px;
}

.cs-branding-accordion .accordion:not(.accordion:first-child) {
  padding-top: 24px;
}

.cs-branding-accordion .accordion-item {
  margin-bottom: 0;
}

.branding-right {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 24px;
  height: calc(100vh - 120px);
  background: var(--neutral-20);
}

.branding-right .preview-brand {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  margin-inline: 10px;
  -webkit-box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.12);
  width: calc(100% - 44px);
}

.branding-right .cs-regular-h3,
.branding-right>span {
  position: absolute;
  top: 17px;
  left: 23px;
}

.branding-container .branding-right>span {
  right: 23px;
  left: auto;
  cursor: pointer;
  color: var(--primary-main);
}

.branding-right .injected-svg {
  width: 100%;
  height: 100%;
}

.cs-branding-accordion.cs-accordion .accordion-item:not(.cs-branding-accordion.cs-accordion .accordion-item:last-child) {
  padding-bottom: 18px;
  margin-bottom: 16px;
  border-radius: 0;
  border-bottom: 1px solid var(--neutral-40);
}

.sketch-picker {
  width: 100% !important;
  height: 200px !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  padding: 10px !important;
  border: 1px solid #c8d0dc !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.color-row {
  position: relative;
}

.cs-reset-btn {
  position: absolute;
  top: 28px;
  right: 18px;
  z-index: 1;
  padding: 0;
}

.sketch-picker>div:first-child {
  width: 50% !important;
  height: 100% !important;
  padding-bottom: 0 !important;
  float: left;
  border-radius: 3px !important;
}

.flexbox-fix {
  width: 50%;
  padding-left: 10px;
}

.flexbox-fix:nth-child(2) {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.flexbox-fix:nth-child(2)>div:nth-child(2) {
  margin: 0 !important;
  width: 48px !important;
  height: 48px !important;
  border-radius: 2px !important;
  margin-bottom: 12px !important;
}

.flexbox-fix:nth-child(2)>div:nth-child(2)>div:first-child {
  position: relative;
}

.flexbox-fix:nth-child(2)>div:nth-child(2)>div:first-child:before {
  content: 'Brand color';
  position: absolute;
  display: block;
  top: 50%;
  left: 100%;
  width: 100%;
  white-space: nowrap;
  -webkit-transform: translate(8px, -50%);
  -ms-transform: translate(8px, -50%);
  transform: translate(8px, -50%);
  text-align: right;
  color: #0a0a0a;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.flexbox-fix:last-child {
  display: none !important;
}

.github-picker {
  display: block !important;
  padding: 0 !important;
  -webkit-column-count: 8;
  -moz-column-count: 8;
  column-count: 8;
  -webkit-column-gap: 16px;
  -moz-column-gap: 16px;
  column-gap: 16px;
  row-gap: 8px;
  counter-reset: item-counter;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 0 !important;
  border-radius: 0 !important;
  margin-block: 16px 8px;
}

.github-picker>span div {
  width: 100% !important;
  height: 46px !important;
  margin-bottom: 8px;
  border-radius: 2px;
}

.github-picker>span div>span div:before {
  content: attr(title);
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  bottom: 2px;
  right: 4px;
  text-align: right;
  opacity: 1 !important;
  color: var(--neutral-10);
  font-size: 8px;
  line-height: 12px;
  font-weight: 300;
  letter-spacing: 0.9px;
  font-family: var(--cs-font) !important;
  /* mix-blend-mode: color-burn; */
}

.github-picker>div:first-child,
.github-picker>div:nth-child(2) {
  border: 0 !important;
}

.color-container .cs-danger {
  margin-top: 8px;
}

.color-container {
  position: relative;
  z-index: 0;
}

.color-row .cs-btn-icon-tertiary {
  margin-top: -10px;
}

.custom-domain-section .cs-form-group:before {
  content: attr(data-title);
  display: block;
  position: absolute;
  top: 50%;
  right: 16px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  color: var(--neutral-70);
}

.upload-image-row {
  position: relative;
  margin-bottom: 10px;
}

.upload-image-row:first-child {
  margin-top: 24px;
}

.cs-preview-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cs-preview-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 20px;
  margin-top: 4px;
  max-height: 92px;
  border: 1px solid var(--neutral-40);
  border-radius: 4px;
  background: var(--neutral-20);
}

.cs-preview-box img {
  width: 100%;
  max-width: 166px;
  height: 70px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

.client-login-section.cs-accordion-active.accordion-item {
  border-bottom: 1px solid var(--neutral-40);
  border-radius: 0;
  padding-bottom: 10px;
}

.upload-image-row .cs-form-check-box {
  min-height: auto;
  margin-bottom: 0;
}

.welcome-message-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 16px;
}

.welcome-message-container .cs-form-textarea {
  margin-bottom: 16px !important;
}

.choose-image-slider .welcome-message-box {
  margin-bottom: 4px;
}

.choose-image-slider .slick-list {
  margin-inline: -6px -6px;
}

.choose-image-slider .slick-slide {
  padding-inline: 6px;
}

.image-box {
  position: relative;
  height: 80px;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  border: 2px solid transparent;
  cursor: pointer;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.choose-template-slider .image-box {
  border-color: var(--neutral-50);
}

.image-box:hover,
.choose-template-slider .image-box:hover,
.selected-slider.image-box {
  border-color: var(--primary-main);
}

.image-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  border-radius: 2px;
}

.cs-preview-box.cs-logo-box {
  padding-block: 8px;
}

.cs-preview-box.cs-logo-box .cs-common-spinner.cs-primary {
  padding-block: 32px;
}

.cs-preview-box .fav-logo {
  width: 64px;
  height: 64px;
}

@keyframes logo-spin {
  0% {
    transform: rotate(0deg) scale(0.8);
  }

  50% {
    transform: rotate(180deg) scale(0.9);
  }

  100% {
    transform: rotate(360deg) scale(0.8);
  }
}

.cs-preview-box .fav-logo.cs-common-spinner {
  animation: logo-spin 1.3s infinite linear;
}

.choose-template-slider {
  margin-top: 6px;
}

.preview-brand>svg {
  position: absolute;
  pointer-events: none;
}

.template-img {
  width: 100%;
  height: 100%;
}

.welcome-text {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 196px;
}

.welcome-text h4 {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 20px;
}

.welcome-text p {
  margin-top: 3px;
  padding-right: 20px;
}

/* Choose Temp Design CSS Start */

.choose-temp-design {
  position: relative;
  height: 550px;
}

.choose-temp-design .container-fluid,
.choose-temp-design .row {
  height: 100%;
}

.choose-temp-design .cs-right-section {
  height: auto;
  padding: 0;
}

.template-right-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  padding-right: 30px;
  text-align: center;
  pointer-events: none;
}

.template-right-section .cs-title {
  margin-top: 12px;
}

.template-right-section .cs-semi-bold-h1 {
  font-size: 12px;
  line-height: 16px;
}

.template-right-section .cs-title::after {
  width: 143px;
  margin-top: 8px;
}

.template-right-section .lg-btn {
  max-width: 100px;
  padding: 6px;
  font-size: 8px;
  line-height: 12px;
  margin-bottom: 20px;
}

.template-right-section .cs-regular-body-text-m,
.template-right-section .cs-light-body-text-m {
  font-size: 8px;
  line-height: 12px;
}

.template-bg {
  min-height: 550px;
}

.template-bg img {
  width: 100%;
  max-width: 490px;
  -o-object-fit: cover;
  object-fit: cover;
}

.template-right-section .auth-main-link {
  padding-block: 6px;
  font-size: 6px;
}

.template-right-section .auth-footer {
  margin-block: 24px 0;
}

.template-right-section .cs-form-group {
  margin-bottom: 14px;
}

.template-right-section .cs-form-group .form-control {
  height: 26px;
  font-size: 8px;
  line-height: 12px;
  padding: 8px;
}

.template-right-section .input-field-icon.cursor-pointer.password-info {
  right: 10px;
}

.template-right-section a.cs-regular-sub-heading-m {
  font-size: 8px;
  line-height: 14px;
  display: block;
}

/* --- Temp2 Design start --- */
.template2-section.choose-temp-design {
  background-color: var(--primary-canvas-surface);
}

.template2-section .template-right-section {
  background-color: var(--neutral-10);
  max-width: 340px;
  min-height: 420px;
  border-radius: 8px;
  padding-inline: 30px;
  margin: 0 auto;
}

/* --- Temp2 Design end --- */

/* --- Temp3 Design start --- */

.template3-section {
  padding: 40px 52px;
}

.template3-section:before {
  content: '';
  display: block;
  position: absolute;
  bottom: -260px;
  left: 50%;
  width: 100vw;
  height: 100%;
  -webkit-transform: translate(-50%, 0) rotate(-15deg);
  -ms-transform: translate(-50%, 0) rotate(-15deg);
  transform: translate(-50%, 0) rotate(-15deg);
  background: var(--primary-surface);
  z-index: 0;
}

.template3-section .welcome-text {
  top: 2vw;
  left: 2vw;
}

.template3-section .container-fluid>.row {
  position: relative;
  background-color: var(--neutral-10);
  -webkit-box-shadow: var(--box-shadow-one);
  box-shadow: var(--box-shadow-one);
  border-radius: 4px;
  z-index: 1;
  overflow: hidden;
}

.template3-section .row .col,
.template3-section .template-left-side,
.template3-section .template-bg,
.template3-section .template-bg img {
  height: 100%;
  min-height: auto;
}

.template3-section .row>.col {
  padding: 0;
}

.template3-section .template-right-section {
  padding-inline: 30px;
}

/* --- Temp3 Design end --- */

/* --- Temp4 Design start --- */
.template4-section {
  padding-inline: 60px;
}

.template4-section .row:first-child .col:not(.col:first-child) {
  height: calc(100% - 120px);
  margin-left: 80px;
}

.template4-section .template-bg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-position: left;
}

.template4-section .template-right-section {
  position: relative;
  z-index: 1;
  padding: 30px 40px;
  background: var(--neutral-10);
  border-radius: 5px;
}

/* --- Temp4 Design end --- */

.delete-icon {
  position: absolute;
  top: 6px;
  right: 6px;
  padding: 4px;
  display: none;
  border-radius: 4px;
  background-color: var(--danger-surface);
  width: 20px;
  max-width: 20px;
  height: 20px;
  max-height: 20px;
  /* Initially hidden */
}

/* Show the delete icon on hover */
.image-container:hover .delete-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* Choose Temp Design CSS End */

/********Settings Full Preview Start ***********/
.settings-main-section .branding-section .branding-container .fullscreen-preview,
.settings-main-section .branding-section .branding-container .fullscreen-preview .preview-brand {
  position: relative !important;
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

.settings-main-section .branding-section .branding-container .fullscreen-preview h3.cs-regular-h3,
.settings-main-section .branding-section .branding-container .fullscreen-preview span.cs-regular-body-text-l {
  display: none;
}

.settings-main-section .exit-fullscreen-button {
  position: absolute;
  top: 40px;
  border-radius: 4px;
  color: var(--primary-main);
  background-color: #eef1ff;
  border: 2px solid #eef1ff;
  -webkit-box-shadow: var(--box-shadow-one);
  box-shadow: var(--box-shadow-one);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  max-width: 130px;
  z-index: 10;
}

.settings-main-section .exit-fullscreen-button:hover,
.settings-main-section .exit-fullscreen-button:target,
.settings-main-section .exit-fullscreen-button:focus-visible,
.settings-main-section .exit-fullscreen-button:focus-within,
.settings-main-section .exit-fullscreen-button:active,
.settings-main-section .exit-fullscreen-button:visited {
  color: var(--primary-main) !important;
  background-color: #eef1ff !important;
  border: 2px solid #eef1ff !important;
}

.settings-main-section .exit-fullscreen-button span:first-child svg,
.settings-main-section .exit-fullscreen-button span:first-child svg path {
  fill: var(--primary-main);
}

.settings-main-section .exit-fullscreen-button span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.settings-main-section .exit-fullscreen-button span:first-child {
  margin-right: 8px;
}

.settings-main-section .fullscreen-preview .choose-temp-design,
.settings-main-section .fullscreen-preview .template-bg,
.settings-main-section .fullscreen-preview .template1-section .template-left-side,
.settings-main-section .fullscreen-preview .template1-section .col {
  height: 100%;
}

.settings-main-section .fullscreen-preview .template4-section .row:first-child .col:not(.col:first-child) {
  height: calc(100% - 255px);
  padding: 0;
  margin: 0;
  max-width: 40vw;
  width: 100%;
}

.settings-main-section .fullscreen-preview .welcome-text {
  top: 80px;
  left: 91px;
  width: 100%;
  max-width: 345px;
}

.settings-main-section .fullscreen-preview .welcome-text h4 {
  font-size: calc(0.9rem + 16px);
  line-height: calc(20px + 20px);
}

.settings-main-section .fullscreen-preview .welcome-text p {
  font-size: calc(8px + 6px);
  line-height: calc(12px + 8px);
  margin-top: 16px;
}

.settings-main-section .fullscreen-preview .template-bg img {
  max-width: 100%;
}

.settings-main-section .fullscreen-preview .template3-section {
  padding: 126px 200px;
}

.settings-main-section .fullscreen-preview .template3-section:before {
  bottom: -50vh;
  width: 130vw;
}

.settings-main-section .fullscreen-preview .template2-section .template-right-section {
  max-width: 30vw;
  min-height: calc(100vh - 255px);
}

.settings-main-section .fullscreen-preview .template4-section {
  padding-inline: 200px;
}

/********Settings Full Preview End ***********/
.logout-modal>span svg {
  margin-bottom: 24px;
}

.cs-step-one .auth-sign-up-scrollbar {
  height: 100%;
  overflow: unset;
}

/*=========================================================================================*/

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1799px) {
  .branding-container .branding-left {
    height: 100%;
    max-height: 84vh;
  }

  /* .branding-right {
    height: 120vh;
  } */

  /* .branding-container {
    height: 130vh;
  } */

  .settings-main-section .single-row-data .collaboration-action-drop .dropdown-menu.show,
  .settings-main-section .multiple-row-data .collaboration-action-drop .dropdown-menu.show {
    -webkit-transform: translate(-30px, -20px) !important;
    -ms-transform: translate(-30px, -20px) !important;
    transform: translate(-30px, -20px) !important;
  }

  .github-picker {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }

  .image-box {
    height: max(80px, 5vw);
  }
}

@media (max-width: 1499px) {
  .auth-section .d-none.d-lg-block.col-lg-6 {
    padding-left: 0;
  }

  .auth-section .cs-right-section {
    padding-inline: 1vw 1.5vw;
    width: 100%;
  }

  /* .auth-section .cs-left-section img {
    max-width: 1300px;
  } */

  .multi-step-header,
  .multi-step-body {
    width: 100%;
  }

  /* .auth-section .cs-right-section .form-error-msg {
      font-size: 8px !important;
      line-height: 12px !important;
    } */

  /* ********************branding page responsive start******************** */
  .accordion-header span:last-child {
    margin: 0;
  }

  .cs-preview-box {
    padding: 8px;
    max-height: 64px;
  }

  .cs-preview-box img {
    max-height: 40px;
  }

  /* .branding-container .branding-left {
    height: 100%;
    max-height: 110vh;
  }

  .branding-right {
    height: 110vh;
  }

  .branding-container {
    height: 125vh;
  } */

  .branding-right .preview-brand {
    width: 100%;
    margin: 0;
  }

  .preview-brand .choose-temp-design .container-fluid {
    padding-inline: 0;
  }

  .branding-container .branding-left {
    padding-inline: 10px;
  }

  .template4-section .row:first-child .col:not(.col:first-child) {
    margin: 0;
    height: calc(100% - 80px);
  }

  .template2-section .template-right-section {
    min-height: 380px;
  }

  /* ********************branding page responsive end******************** */
  .settings-main-section .fullscreen-preview .welcome-text h4 {
    font-size: calc(0.9rem + 10px);
    line-height: calc(20px + 14px);
  }

  .settings-main-section .fullscreen-preview .welcome-text p {
    font-size: calc(8px + 4px);
    line-height: calc(12px + 4px);
  }

  .settings-main-section .template3-section:before {
    bottom: -175px;
  }

  .choose-temp-design {
    height: 460px;
  }

  .template-bg {
    min-height: 460px;
  }
}

@media (max-width: 1199px) {
  .auth-section .cs-left-section img {
    max-width: 1150px;
  }

  .auth-section .align-items-center .cs-right-section .cs-auth-header p,
  .auth-section .mfa-row {
    padding-inline: 0;
  }

  .cs-preview-box,
  .cs-preview-box img {
    max-height: 44px;
  }

  .cs-preview-box .fav-logo {
    width: 20px;
    height: 20px;
  }

  /* .settings-main-section .choose-image-slider .image-box {
    height: 100px;
  } */
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {

  .auth-section,
  .auth-section .cs-accordion .accordion-item {
    background-color: transparent !important;
  }

  .auth-section .align-items-center {
    height: 100vh;
  }

  .auth-section .last-form-field:first-child .cs-form-group {
    margin-bottom: 24px;
  }

  .auth-section .cs-right-section {
    width: 100%;
    height: 100%;
    margin: 48px auto;
    padding-inline: 6vw;
  }

  .auth-section .auth-sign-up-scrollbar {
    height: auto;
    margin: 0;
    padding-inline: 0;
  }

  .auth-section .resp-last-field,
  .auth-section .resp-last-field .cs-form-group {
    margin-bottom: 0 !important;
  }

  .auth-section .mfa-row:first-child .row>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
  }

  .auth-section .mfa-box {
    width: 100%;
    max-width: 500px;
  }

  .auth-section .multi-step-header {
    padding-inline: 0px 6px;
  }

  .auth-section .accordion-header span:last-child {
    margin: 0;
  }

  /* ********************branding page responsive start******************** */
  .branding-container .branding-left {
    margin-bottom: 32px;
  }

  .github-picker {
    -webkit-column-count: 8;
    -moz-column-count: 8;
    column-count: 8;
  }

  .sketch-picker {
    height: 200px !important;
  }

  .cs-preview-box {
    height: 100px;
    max-height: 100%;
  }

  .cs-preview-box img,
  .cs-preview-box .fav-logo {
    width: initial;
    height: initial;
  }

  .image-box {
    height: initial;
  }

  .branding-right {
    height: calc(100vh + 60px);
    margin-top: 24px;
  }

  .preview-brand .choose-temp-design .align-items-center {
    height: 100%;
  }

  .welcome-text {
    top: 20px;
    left: 20px;
    width: 100px;
  }

  .branding-section .preview-brand .choose-temp-design {
    background-color: var(--neutral-10) !important;
  }

  .branding-section .preview-brand .template2-section.choose-temp-design {
    background-color: var(--primary-canvas-surface) !important;
  }

  .branding-container,
  .branding-container .branding-left {
    height: auto;
    max-height: 100%;
  }

  /* ********************branding page responsive end******************** */
}

@media (max-width: 767px) {
  .auth-section .cs-right-section {
    padding-inline: 10vw;
  }

  .auth-section .mfa-box {
    max-width: 440px;
  }
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {
  .auth-section .align-items-center {
    height: 100%;
    -webkit-box-align: unset !important;
    -ms-flex-align: unset !important;
    align-items: unset !important;
  }

  .auth-section .align-items-center .cs-right-section .cs-auth-header p,
  .auth-section .multi-step-header,
  .auth-section .last-form-field:first-child .cs-form-group {
    margin-bottom: 16px;
  }

  .auth-section .cs-right-section {
    padding: 20px 15px !important;
    height: 100vh;
    margin: 0;
  }

  .auth-section .cs-auth-form .cs-modal-btn {
    margin-top: 24px;
  }

  .auth-section .mfa-box {
    padding: 12px 16px;
    margin-bottom: 16px;
  }

  .auth-section .cs-right-section .cs-logo,
  .auth-section .qr-code {
    margin-bottom: 24px;
  }

  .auth-section .cs-right-section .auth-footer {
    margin-block: 24px 16px;
  }

  .auth-section .qr-code img {
    width: 183px;
    height: 183px;
  }

  .auth-section .auth-footer button {
    margin-block: 24px 16px;
  }

  .auth-section .auth-main-link {
    padding: 8px 24px;
  }

  .auth-section .mfa-box .radio-button {
    margin-right: 0;
  }

  .auth-section .mfa-box {
    max-width: 360px;
  }

  .auth-section .google-map.dropdown-item img {
    width: 18px;
    height: 18px;
  }

  .github-picker {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }

  .cs-avatar-row .profile span.cs-primary {
    bottom: -8px;
  }
}

@media (max-width: 425px) {}

@media (max-width: 360px) {}

@media (max-width: 320px) {}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */

@media only screen and (max-width: 1499px),
screen and (max-height: 900px) {
  .cs-main-layout .settings-main-section .branding-container {
    padding-inline: 0;
    /* height: calc(100vh - 27vh); */
    height: 84vh;
    overflow: hidden;
  }

  .cs-main-layout .settings-main-section .branding-container .branding-left {
    max-height: 100%;
    padding-right: 0;
  }

  .cs-main-layout .branding-container .branding-left .cs-branding-accordion {
    height: 100%;
    max-height: 64vh;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 10px;
  }
}