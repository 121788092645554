/* ==========  START - Root ========== */
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--cs-font);
  font-style: normal;
  background: var(--primary-canvas-surface);
  overflow-x: hidden !important;
}

.cs-text-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.row {
  --bs-gutter-x: 2rem;
}

.cs-text-center {
  text-align: center;
}

p {
  margin: 0px;
}

a {
  text-decoration: none;
  color: var(--primary-main);
}

a:hover {
  color: var(--primary-pressed);
}

a:focus,
a:active {
  color: var(--primary-main);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0px;
}

.cs-link {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer !important;
}

.form-error-msg {
  position: absolute;
  display: block;
  bottom: auto;
  left: 0;
  margin-bottom: 0;
  font-size: 10px;
  line-height: 16px;
  z-index: 2;
}

.cs-new-user-modal .cs-create-company-modal-error {
  position: relative;
}

.icon-error-msg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.icon-error-msg svg {
  margin: 0;
}

.cs-form-textarea .form-error-msg {
  bottom: -10px;
}

.cs-divider {
  position: relative;
  width: calc(100% - 102px);
  text-align: center;
  margin: 16px auto;
}

.cs-divider .cs-regular-body-text-s {
  position: relative;
  width: 100%;
  text-align: center;
}

.cs-divider:before {
  content: ' ';
  display: block;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background: var(--neutral-20);
}

.cs-divider span {
  position: relative;
  color: var(--neutral-50);
  background: var(--neutral-10);
  padding: 0 16px;
}

.for-des {
  display: block !important;
}

.for-mob {
  display: none !important;
}

.for-flex-des {
  display: flex !important;
}

.for-flex-mob {
  display: none !important;
}

/* ==========  END - Root ========== */

/* ==========  START - Pseudo Elements  ========== */

::-webkit-scrollbar-thumb:hover {
  background: var(--neutral-60);
}

.cs-files .cs-scroller {
  display: block;
  width: 100%;
  height: auto;
  max-height: 250px;
  margin-right: -4px;
  padding-right: 4px;
}

.cs-scroller {
  position: relative;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: var(--neutral-10);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: var(--neutral-50);
  border-radius: 5px;
}

::-moz-selection {
  color: var(--neutral-10);
  fill: var(--neutral-10);
  background-color: var(--primary-main);
}

::selection {
  color: var(--neutral-10);
  fill: var(--neutral-10);
  background-color: var(--primary-main);
}

::-moz-selection {
  color: var(--neutral-10);
  fill: var(--neutral-10);
  background-color: var(--primary-main);
}

/* ==========  END - Pseudo Elements ========== */

/* ========== START - Typography ========== */

.cs-semi-bold-h1 {
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
}

.cs-regular-h1 {
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
}

.cs-semi-bold-h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.cs-regular-h2 {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.cs-semi-bold-h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.cs-regular-h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.cs-semi-bold-h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.cs-regular-h4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.cs-semi-bold-h5 {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.cs-regular-h5 {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.cs-semi-bold-h6 {
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
}

.cs-regular-h6 {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
}

.cs-medium-sub-heading-xl {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.cs-regular-sub-heading-xl {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.cs-medium-sub-heading-l {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.cs-regular-sub-heading-l {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.cs-medium-sub-heading-m {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.cs-regular-sub-heading-m {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.cs-medium-sub-heading-s {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.cs-regular-sub-heading-s {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.cs-medium-sub-heading-xs {
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
}

.cs-regular-sub-heading-xs {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
}

.cs-medium-sub-heading-xxs {
  font-size: 8px;
  font-weight: 500;
  line-height: 12px;
}

.cs-regular-sub-heading-xxs {
  font-size: 8px;
  font-weight: 400;
  line-height: 12px;
}

.cs-regular-body-text-xl {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.cs-light-body-text-xl {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

.cs-regular-body-text-l {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.cs-light-body-text-l {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

.cs-regular-body-text-m {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.cs-light-body-text-m {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}

.cs-regular-body-text-s {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
}

.cs-light-body-text-s {
  font-size: 10px;
  font-weight: 300;
  line-height: 16px;
}

.cs-regular-body-text-xs {
  font-size: 8px;
  font-weight: 400;
  line-height: 12px;
}

.cs-light-body-text-xs {
  font-size: 8px;
  font-weight: 300;
  line-height: 12px;
}

/* ========== END - Typography ========== */

/* ========== START - Color Styles ========== */

.cs-warning {
  color: var(--warning-main);
}

.cs-success {
  color: var(--success-main);
}

.cs-info {
  color: var(--info-main);
}

.cs-primary {
  color: var(--primary-main);
}

.cs-neutral-100 {
  color: var(--neutral-100);
}

.cs-neutral-90 {
  color: var(--neutral-90);
}

.cs-neutral-80 {
  color: var(--neutral-80);
}

.cs-neutral-70 {
  color: var(--neutral-70);
}

.cs-neutral-60 {
  color: var(--neutral-60);
}

.cs-neutral-50 {
  color: var(--neutral-50);
}

.cs-neutral-40 {
  color: var(--neutral-40);
}

.cs-neutral-30 {
  color: var(--neutral-30);
}

.cs-neutral-20 {
  color: var(--neutral-20);
}

.cs-neutral-10 {
  color: var(--neutral-10);
}

.cs-danger,
.cs-disabled .form-error-msg {
  color: var(--danger-main) !important;
}

.cs-danger .icon svg path,
.cs-danger span svg path,
.cs-danger svg path {
  fill: var(--danger-main);
}

.cs-warning .icon svg path,
.cs-warning span svg path,
.cs-warning svg path {
  fill: var(--warning-main);
}

.cs-success .icon svg path,
.cs-success span svg path,
.cs-success svg path {
  fill: var(--success-main);
}

.cs-info .icon svg path,
.cs-info span svg path,
.cs-info svg path {
  fill: var(--info-main);
}

.cs-primary .icon svg path,
.cs-primary span svg path,
.cs-primary svg path {
  fill: var(--primary-main) !important;
}

.cs-neutral-100 .icon svg path,
.cs-neutral-100 span svg path,
.cs-neutral-100 svg path {
  fill: var(--neutral-100);
}

.cs-neutral-90 .icon svg path,
.cs-neutral-90 span svg path,
.cs-neutral-90 svg path {
  fill: var(--neutral-90);
}

.cs-neutral-80 .icon svg path,
.cs-neutral-80 span svg path,
.cs-neutral-80 svg path {
  fill: var(--neutral-80);
}

.cs-neutral-70 .icon svg path,
.cs-neutral-70 span svg path,
.cs-neutral-70 svg path {
  fill: var(--neutral-70);
}

.cs-neutral-60 .icon svg path,
.cs-neutral-60 span svg path,
.cs-neutral-60 svg path {
  fill: var(--neutral-60) !important;
}

.cs-neutral-50 .icon svg path,
.cs-neutral-50 span svg path,
.cs-neutral-50 svg path {
  fill: var(--neutral-50);
}

.cs-neutral-40 .icon svg path,
.cs-neutral-40 span svg path,
.cs-neutral-40 svg path {
  fill: var(--neutral-40);
}

.cs-neutral-30 .icon svg path,
.cs-neutral-30 span svg path,
.cs-neutral-30 svg path {
  fill: var(--neutral-30);
}

.cs-neutral-20 .icon svg path,
.cs-neutral-20 span svg path,
.cs-neutral-20 svg path {
  fill: var(--neutral-20);
}

.cs-neutral-10 .icon svg path,
.cs-neutral-10 span svg path,
.cs-neutral-10 svg path {
  fill: var(--neutral-10);
}

.cs-asset svg path {
  fill: var(--asset-main) !important;
}

.cs-liability svg path {
  fill: var(--liability-main) !important;
}

.cs-font-color-sub-title {
  color: var(--font-color-sub-title);
}

.cs-disabled-icon svg path .cs-disabled svg,
.cs-disabled svg path {
  fill: var(--neutral-50) !important;
}

/* ========== END - Color Styles ========== */

/* ========== START - Button style ========== */

/* ----- Primary button start----- */
.cs-btn-primary {
  position: relative;
  padding: 10px;
  color: var(--neutral-10);
  background: var(--primary-main);
  border-radius: 4px;
  border: 2px solid var(--primary-main);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: var(--box-shadow-one);
  box-shadow: var(--box-shadow-one);
  line-height: 16px;
}

.cs-btn-primary:hover {
  background: var(--primary-hover);
  border-color: var(--primary-hover);
}

.cs-btn-primary:active,
.cs-btn-primary:focus-visible,
.cs-btn-primary:focus {
  background: var(--primary-pressed) !important;
  border-color: var(--primary-pressed) !important;
}

.cs-btn-primary:disabled,
.cs-btn-icon-primary:disabled,
.cs-btn-danger:disabled {
  color: var(--neutral-60) !important;
  border: 2px solid var(--neutral-40) !important;
  padding: 11px 10px !important;
  background: var(--neutral-20) !important;
  cursor: not-allowed;
  pointer-events: auto;
}

.cs-btn-primary:disabled:focus,
.cs-btn-icon-primary:disabled:focus,
.cs-btn-danger:disabled:focus,
.cs-btn-primary:disabled:focus-visible,
.cs-btn-icon-primary:disabled:focus-visible,
.cs-btn-danger:disabled:focus-visible,
.cs-btn-primary:disabled:active,
.cs-btn-icon-primary:disabled:active,
.cs-btn-danger:disabled:active {
  color: var(--neutral-60) !important;
  border: 2px solid var(--neutral-40) !important;
  padding: 11px 10px !important;
  background: var(--neutral-20) !important;
  cursor: not-allowed;
}

/* ----- Primary button end ----- */

/* ----- Secondary button start -----*/
.cs-btn-secondary {
  padding: 10px;
  color: var(--primary-main);
  background: var(--neutral-10);
  border-radius: 4px;
  border: 2px solid var(--primary-main);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 16px;
}

.cs-btn-secondary:hover {
  color: var(--primary-hover);
  background: var(--primary-surface);
  border: 2px solid var(--primary-hover);
  -webkit-box-shadow: var(--box-shadow-one);
  box-shadow: var(--box-shadow-one);
}

.cs-btn-secondary:active,
.cs-btn-secondary:focus-visible,
.cs-btn-secondary:focus {
  color: var(--primary-pressed) !important;
  background: var(--primary-surface) !important;
  border: 2px solid var(--primary-pressed) !important;
}

.cs-btn-secondary:disabled,
.cs-btn-icon-secondary:disabled {
  color: var(--neutral-60) !important;
  border: 2px solid var(--neutral-40) !important;
  background: var(--neutral-20) !important;
  cursor: not-allowed !important;
  box-shadow: var(--box-shadow-one);
  pointer-events: auto;
}

/* ----- Secondary button end -----*/

/* ----- Tertiary button start ----- */
.cs-btn-tertiary {
  padding: 10px;
  color: var(--primary-main);
  border-radius: 4px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent !important;
  line-height: 16px;
}

.cs-btn-tertiary:hover {
  color: var(--primary-hover);
}

.cs-btn-tertiary:active,
.cs-btn-tertiary:focus-visible,
.cs-btn-tertiary:focus {
  color: var(--primary-pressed) !important;
}

.cs-btn-tertiary:disabled {
  color: var(--neutral-60);
}

/* ----- Tertiary button end ----- */

/* ----- Start Primary Button With Icon ----- */

.cs-btn-icon-primary {
  border-radius: 4px;
  color: var(--neutral-10) !important;
  background-color: var(--primary-main);
  border: 2px solid var(--primary-main);
  -webkit-box-shadow: var(--box-shadow-one);
  box-shadow: var(--box-shadow-one);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 24px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 16px;
}

.cs-btn-icon-primary:hover {
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
}

.cs-btn-icon-primary:active,
.cs-btn-icon-primary:focus-visible {
  background-color: var(--primary-pressed) !important;
  border-color: var(--primary-pressed) !important;
}

.cs-btn-icon-primary span.cs-neutral-10,
.cs-btn-icon-secondary span.cs-primary,
.cs-btn-icon-tertiary span.cs-primary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 8px;
}

.cs-btn-icon-primary:disabled span.cs-neutral-10 svg path,
.cs-btn-icon-secondary:disabled span.cs-primary svg path,
.cs-btn-icon-tertiary:disabled span.cs-primary svg path {
  fill: var(--neutral-60);
}

.cs-btn-icon-secondary:hover span.cs-primary svg path,
.cs-btn-icon-tertiary:hover span.cs-primary svg path {
  fill: var(--primary-hover);
}

.cs-btn-icon-secondary:active span.cs-primary svg path,
.cs-btn-icon-tertiary:active span.cs-primary svg path {
  fill: var(--primary-pressed);
}

.cs-btn-icon-tertiary:disabled,
.cs-btn-icon-tertiary:active:disabled,
.cs-btn-icon-tertiary:focus-visible:disabled {
  color: var(--neutral-60) !important;
  pointer-events: all !important;
  cursor: not-allowed !important;
}

.cs-btn-icon-tertiary:disabled svg path {
  fill: var(--neutral-60) !important;
}

/* ----- End Primary Button With Icon ----- */

/* ----- Start Secondary Button With Icon ----- */
.cs-btn-icon-secondary {
  border-radius: 4px;
  border: 2px solid var(--primary-main) !important;
  -webkit-box-shadow: var(--box-shadow-one);
  box-shadow: var(--box-shadow-one);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--primary-main);
  background-color: transparent;
  line-height: 16px;
}

.cs-btn-icon-secondary:hover {
  border: 2px solid var(--primary-hover);
  color: var(--primary-hover);
  background-color: var(--primary-surface);
}

.cs-btn-icon-secondary:active,
.cs-btn-icon-secondary:focus-visible {
  border: 2px solid var(--primary-pressed) !important;
  background-color: var(--primary-surface) !important;
  color: var(--primary-pressed) !important;
}

/* ----- End Secondary Button With Icon ----- */

/* ----- Start Tertiary Button With Icon ----- */
.cs-btn-icon-tertiary {
  border-radius: 4px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--primary-main);
  background-color: transparent !important;
  line-height: 16px;
}

.cs-btn-icon-tertiary:hover {
  color: var(--primary-hover);
}

.cs-btn-icon-tertiary:active,
.cs-btn-icon-tertiary:focus-visible {
  color: var(--primary-pressed) !important;
}

/* ----- End Tertiary Button With Icon ----- */

/* ----- Button size start ----- */
.sm-btn {
  width: 100%;
  max-width: 101px;
}

.md-btn {
  width: 100%;
  max-width: 149px;
}

.lg-btn {
  width: 100%;
  max-width: 197px;
}

.xl-btn {
  width: 100%;
  max-width: 245px;
}

/* ----- Button size end ----- */

/* ----- Danger button ----- */
.cs-btn-danger {
  padding: 10px;
  color: var(--neutral-10);
  background: var(--danger-main);
  border-radius: 4px;
  border: 2px solid var(--danger-main) !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 16px;
}

.cs-btn-danger:hover {
  background: var(--danger-hover);
}

.cs-btn-danger:active,
.cs-btn-danger:focus-visible,
.cs-btn-danger:focus {
  background: var(--danger-pressed) !important;
}

/* ----- Success button ----- */
.cs-btn-success {
  padding: 10px;
  color: var(--neutral-10);
  background: var(--success-main);
  border-radius: 4px;
  border: 2px solid var(--success-main) !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 16px;
}

.cs-btn-success:hover {
  background: var(--success-hover);
}

.cs-btn-success:active,
.cs-btn-success:focus-visible,
.cs-btn-success:focus {
  background: var(--success-pressed) !important;
}

.cs-btn-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 100%;
}

.cs-btn-group> :first-child {
  margin-right: 16px;
}

/* ========== END - Button style ========== */

/* ==========  START - Common Input fields  ========== */

.cs-form-group {
  position: relative;
  text-align: left;
  margin-bottom: 24px;
}

.last-form-field,
.last-form-field .cs-form-group,
.resp-last-field,
.resp-last-field .cs-form-group {
  margin-bottom: 0;
}

.cs-form-group .form-label,
.form-label {
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--neutral-100);
  margin-bottom: 4px;
}

.cs-form-group .form-control,
.cs-form-group .cs-textarea,
.cs-form-group .cs-select-group {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  height: 40px;
  width: 100%;
  color: var(--neutral-100);
  border-radius: 4px;
  padding: 10px 16px;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-20);
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.cs-form-group .form-control:focus-visible,
.cs-input-field-active.form-control,
.cs-input-field-active.cs-textarea,
.cs-form-group .cs-textarea:focus-visible {
  background: var(--neutral-10);
}

.cs-form-group.cs-disabled .form-label {
  color: var(--neutral-60);
}

.cs-disabled {
  cursor: not-allowed !important;
}

.cs-disabled .form-control::-webkit-input-placeholder,
.cs-disabled .form-control::-moz-placeholder,
.cs-disabled .form-control:-ms-input-placeholder,
.cs-disabled .form-control::-ms-input-placeholder {
  background-color: var(--neutral-40) !important;
  border-color: var(--neutral-40) !important;
  color: var(--neutral-60);
  cursor: not-allowed;
}

.cs-form-group .form-control:disabled,
.cs-disabled .react-date-picker--disabled,
.cs-common-add-dropdown .dropdown .dropdown-toggle:disabled,
.cs-disabled .form-control,
.cs-disabled .form-control::placeholder,
.cs-disabled .PhoneInputInput,
.cs-disabled .css-16xfy0z-control {
  background-color: var(--neutral-40) !important;
  border-color: var(--neutral-40) !important;
  color: var(--neutral-60);
  cursor: not-allowed !important;
  pointer-events: all !important;
  font-weight: 300 !important;
}

.cs-date-picker-calender,
.cs-date-picker-calender .react-calendar__tile,
.dropdown-item,
.dropdown-toggle {
  font-size: 12px !important;
  font-weight: 300 !important;
  line-height: 16px !important;
}

.input-field-icon {
  position: absolute;
  top: 50%;
  right: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

/* ----- START - DatePicker Calender ----- */

.cs-date-picker-calender {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  padding: 10px 10px 10px 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-20);
  color: var(--neutral-70);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  height: 40px;
}

.cs-date-picker-calender .react-date-picker__inputGroup__input {
  border: none;
  outline: none;
  cursor: pointer;
}

.cs-date-picker-calender .react-calendar {
  border-radius: 4px;
  -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
  border: none;
}

.cs-date-picker-calender .react-date-picker__calendar--open {
  width: 100%;
  z-index: 3;
}

.cs-add-acc-modal .cs-form-group .react-date-picker--disabled {
  border: 1px solid var(--neutral-40);
  background: var(--neutral-40);
  color: var(--neutral-60);
}

.cs-form-group .react-date-picker--enabled.cs-input-field-active.cs-date-picker-calender {
  background: var(--neutral-10);
  color: var(--neutral-100);
}

.cs-date-picker-calender .react-calendar__tile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
  color: var(--neutral-90);
  border: 1px solid transparent !important;
}

.cs-date-picker-calender .react-calendar__tile--active {
  background: var(--primary-main) !important;
  color: var(--neutral-10) !important;
  border-radius: 4px;
}

.cs-date-picker-calender .react-calendar__tile--now {
  border: 1px solid var(--primary-main);
  background: var(--primary-surface);
}

.cs-date-picker-calender .react-calendar__tile:disabled {
  border: 1px solid var(--neutral-50);
  background: var(--neutral-40);
  color: var(--neutral-60);
  cursor: not-allowed;
}

.cs-date-picker-calender .react-calendar__tile:enabled:hover,
.cs-date-picker-calender .react-calendar__tile:enabled:focus {
  border-color: var(--primary-main) !important;
  background: transparent;
  border-radius: 4px;
}

/* ----- END - DatePicker Calender ----- */

.cs-add-acc-modal {
  padding: 24px 32px;
}

.cs-form-group .cs-textarea {
  height: 90px;
}

.cs-search-bar .cs-form-group {
  margin-bottom: 0;
}

.cs-search-bar .cs-form-group .form-control,
.associate-add-more .cs-form-group .form-control {
  padding-right: 45px;
}

.cs-search-bar .cs-form-group .form-control:hover {
  border: 1px solid var(--primary-hover);
}

.cs-search-bar .cs-form-group .form-control:active,
.cs-search-bar .cs-form-group .form-control:focus-visible {
  border: 1px solid var(--neutral-50);
}

.form-control.PhoneInput {
  display: block;
  padding: 0;
}

.cs-form-group .PhoneInputInput {
  border: none;
  background: var(--neutral-20);
  outline: none;
  height: 100%;
  width: 100%;
  padding: 10px 16px 10px 46px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}

.cs-form-group .PhoneInputInput:focus-visible,
.cs-form-group .cs-input-field-active .PhoneInputInput {
  background: var(--neutral-10);
}

.form-control.PhoneInput .PhoneInputCountry {
  position: absolute;
  top: 50%;
  left: 16px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

input[type="password"] {
  appearance: none !important;
  -webkit-appearance: none !important;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

/* ----- common dropdown start -----*/
.dropdown .dropdown-toggle {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--neutral-70);
  padding: 10px 16px;
  border-radius: 4px;
  height: 40px;
  background: var(--neutral-10);
}

.dropdown .dropdown-toggle::after {
  display: none;
}

.dropdown .cs-active-accounts,
.dropdown .cs-active-accounts span,
.dropdown .cs-active-accounts span h6,
.dropdown .cs-active-accounts .cs-light-body-text-s,
.cs-common-add-dropdown .dropdown .dropdown-menu.show .dropdown-item.cs-selected-dropdown {
  background: var(--primary-surface);
  color: var(--primary-main) !important;
}

.dropdown .dropdown-toggle:active {
  border-color: var(--neutral-50);
  color: var(--neutral-70);
  background: var(--neutral-10);
}

.option-btn button {
  padding: 0 !important;
  border: 0 !important;
  outline: none !important;
}

.cs-common-add-dropdown.option-btn .dropdown-menu {
  width: 200px !important;
}

.cs-dropdown-nav .dropdown-menu {
  width: 183px;
  border-radius: 5px;
  background: var(--neutral-10);
  -webkit-box-shadow: var(--box-shadow-two);
  box-shadow: var(--box-shadow-two);
  padding-top: 8px;
  padding-bottom: 8px;
  left: -140px !important;
  border: none;
  margin-top: 0px !important;
}

.cs-dropdown-nav .dropdown-menu a {
  padding: 8px 24px;
  color: var(--neutral-90);
}

.cs-dropdown-nav .dropdown-menu a:hover,
.cs-dropdown-nav .dropdown-menu a:active,
.cs-dropdown-nav .dropdown-menu a:focus,
.cs-dropdown-nav .dropdown-menu a:focus-visible {
  background-color: var(--primary-surface);
  color: var(--primary-main);
}

.cs-dropdown-nav .dropdown-menu a:hover path,
.cs-dropdown-nav .dropdown-menu a:active path,
.cs-dropdown-nav .dropdown-menu a:focus path,
.cs-dropdown-nav .dropdown-menu a:focus-visible path {
  fill: var(--primary-main);
}

.cs-dropdown-nav .dropdown-menu a svg {
  margin-right: 16px;
}

.btn:focus-visible {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/* ----- end common dropdown ----- */

/* ----- Start Progressbar ----- */
.cs-progress-bar {
  width: 100%;
  margin: 18px auto 24px;
}

.cs-progress-bar .progress {
  width: 100%;
  height: 30px;
  border-radius: 4px;
  background: var(--neutral-40);
}

.cs-progress-bar .progress-bar {
  border-radius: 4px;
  background: var(--primary-main);
}

/* ----- End Progressbar ----- */

/* ----- Form dropdown start ----- */
.cs-form-dropdown .dropdown-toggle {
  border: 1px solid var(--neutral-50);
  position: relative;
}

.cs-address-dropdown .dropdown-toggle {
  padding: 0;
  border: 0;
}

.cs-form-dropdown .dropdown-item {
  padding: 12px 16px;
  color: var(--neutral-90);
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}

.cs-form-dropdown .dropdown-item:hover,
.dropdown-item.active,
.dropdown-item:active {
  background: var(--primary-surface) !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--primary-main) !important;
}

.cs-form-dropdown .dropdown-item-active {
  color: var(--primary-main);
  background: var(--primary-surface);
}

.cs-form-dropdown .dropdown-menu.show {
  width: 100%;
  -webkit-box-shadow: var(--box-shadow-two);
  box-shadow: var(--box-shadow-two);
  border: 0;
}

.cs-form-dropdown {
  position: relative;
  margin-bottom: 24px;
}

.cs-form-dropdown .cs-scroller {
  max-height: 160px;
}

.view-and-manage-container .cs-table-widget.cs-scroller {
  max-height: 320px;
  overflow-y: auto !important;
}

.view-and-manage-header {
  display: flex;
  align-items: center;
}

.view-and-manage-header .cs-search-bar {
  padding-inline: 6px 0;
}

.view-and-manage-top {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: row-reverse;
}

.cs-form-dropdown .dropdown-toggle:active {
  background: var(--neutral-10);
  color: var(--neutral-70);
  border-color: var(--neutral-50);
}

.google-map.dropdown-item {
  padding-bottom: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.google-map.dropdown-item img {
  width: 22px;
  height: 22px;
  margin-left: 14px;
}

/* ----- Form dropdown end ----- */

/* ----- CheckBox start ----- */
.cs-form-check-box {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-form-check-box .form-check-input {
  width: 16px;
  height: 16px;
  border: 1px solid var(--neutral-50);
  margin: 0;
  cursor: pointer;
}

.cs-form-check-box .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.cs-form-check-box .form-check-input:active {
  -webkit-filter: none;
  filter: none;
}

.cs-form-check-box .form-check-input:checked {
  background-color: var(--primary-main);
  border-color: var(--primary-main);
}

.cs-form-check-box .form-check-input:hover {
  border: 1px solid var(--primary-hover);
}

.form-check-input:disabled {
  pointer-events: all !important;
  cursor: not-allowed !important;
  border-color: var(--neutral-50) !important;
}

.cs-form-check-box .form-check-label {
  cursor: pointer;
  padding-left: 8px;
}

.cs-form-check-box .form-check-label span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cs-thead .cs-form-check-box.select-all.form-check:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 2px;
  background: var(--primary-main);
  cursor: pointer;
  pointer-events: none;
  z-index: 0;
}

/* ----- CheckBox end ----- */

/* ----- Radio button start ----- */
.cs-radio-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-radio-group .cs-radio-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 40px;
}

.radio-button {
  width: 18px;
  height: 18px;
  -webkit-box-shadow: 0px 0px 0px 1px var(--neutral-50);
  box-shadow: 0px 0px 0px 1px var(--neutral-50);
  border-radius: 50%;
  background: var(--neutral-10);
  border: 4px solid var(--neutral-10);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.radio-button:hover {
  -webkit-box-shadow: 0px 0px 0px 1px var(--primary-hover);
  box-shadow: 0px 0px 0px 1px var(--primary-hover);
}

.radio-button:checked {
  background: var(--primary-main);
  -webkit-box-shadow: 0px 0px 0px 1px var(--primary-main);
  box-shadow: 0px 0px 0px 1px var(--primary-main);
}

.radio-button:checked:hover {
  -webkit-box-shadow: 0px 0px 0px 1px var(--primary-main);
  box-shadow: 0px 0px 0px 1px var(--primary-main);
  border: 4px solid var(--neutral-10);
}

.cs-radio-btn-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.cs-radio-btn-label span,
.cs-radio-group .cs-radio-btn span {
  margin-left: 8px;
}

/* ----- Radio button end ----- */

/* ----- Textbox - react date picker start ----- */
.cs-form-group .react-date-picker__wrapper {
  border: none;
}

/* ----- Textbox - react date picker end ----- */

/* ----- start - loan month year textbox - dropdown ----- */
.cs-form-group .form-text-group-loan {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.form-text-group-loan .dropdown-toggle {
  width: 160px;
}

.form-text-group-loan .cs-form-dropdown {
  margin-bottom: 0px;
}

.cs-lg-modal .amortization-schedule-container {
  margin-bottom: 24px;
}

/* ----- end - loan month year textbox - dropdown ----- */

/* ----- MFA box CSS start ----- */
.mfa-box {
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-10);
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 18px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 15px 24px;
}

.mfa-inner,
.mfa-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
}

.mfa-options {
  margin-right: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.mfa-box:hover,
.mfa-box.selected-box {
  border: 1px solid var(--primary-main);
  background-color: var(--primary-surface);
}

.back-to-sign-in .cs-btn-tertiary {
  margin: 0 auto;
}

/* ----- MFA box CSS end ----- */

.css-894a34-indicatorSeparator {
  display: none;
}

.select-dropdown .css-1nmdiq5-menu,
.cs-dropdown .css-1nmdiq5-menu {
  position: absolute !important;
  z-index: 99;
  margin-top: 2px !important;
  font-weight: 300 !important;
}

.select-dropdown .css-1nmdiq5-menu>div,
.cs-dropdown .css-1nmdiq5-menu>div {
  background-color: var(--neutral-10);
  max-height: 160px;
  overflow: hidden auto;
  box-shadow: var(--box-shadow-two);
  padding-block: 8px !important;
  border-radius: 6px !important;
}

.select-dropdown .css-1nmdiq5-menu>div>div,
.cs-dropdown .css-1nmdiq5-menu>div>div {
  padding: 9px 16px;
}

.select-dropdown .css-b62m3t-container .css-t3ipsp-control:hover,
.select-dropdown .css-b62m3t-container .css-t3ipsp-control:focus-visible,
.select-dropdown .css-b62m3t-container .css-t3ipsp-control:focus,
.select-dropdown .css-b62m3t-container .css-t3ipsp-control:active,
.select-dropdown .css-b62m3t-container .css-t3ipsp-control:visited,
.select-dropdown .css-b62m3t-container .css-t3ipsp-control:focus-within {
  border-color: var(--primary-main) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.cs-form-textarea {
  margin-bottom: 20px !important;
}

.cs-dropdown.select-dropdown {
  margin-top: 0 !important;
}

input[type='file']::-webkit-file-upload-button {
  display: none;
}

.select-dropdown .css-1u9des2-indicatorSeparator,
.cs-dropdown .css-1u9des2-indicatorSeparator,
input[type='file']::file-selector-button {
  display: none;
}

.select-dropdown .css-13cymwt-control,
.cs-dropdown .css-13cymwt-control,
.select-dropdown .css-t3ipsp-control,
.cs-dropdown .css-t3ipsp-control {
  padding-inline: 14px 6px !important;
  cursor: pointer;
}

.css-t3ipsp-control>div,
.css-13cymwt-control>div {
  padding-left: 0 !important;
}

.select-dropdown .css-1jqq78o-placeholder,
.cs-dropdown .css-1jqq78o-placeholder,
.select-dropdown .css-1dimb5e-singleValue,
.cs-dropdown .css-1dimb5e-singleValue {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  color: var(--neutral-100);
}

.team-collaboration-table .select-dropdown .css-1fdsijx-ValueContainer div,
.team-collaboration-table .cs-dropdown .css-1fdsijx-ValueContainer div {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  max-width: calc(100% - 15px);
}

.cs-dropdown .css-1hb7zxy-IndicatorsContainer,
.cs-dropdown .css-1wy0on6 {
  position: relative;
}

.cs-dropdown .css-1hb7zxy-IndicatorsContainer::before,
.cs-dropdown .css-1wy0on6::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 8px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 18px;
  height: 18px;
  mask-image: url(../images/icons/chevron-down.svg);
  -webkit-mask-image: url(../images/icons/chevron-down.svg);
  background-color: var(--neutral-90);
  mask-size: cover;
  -webkit-mask-size: cover;
  mask-position: center;
  -webkit-mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
}

.cs-dropdown .css-1hb7zxy-IndicatorsContainer svg,
.cs-dropdown .css-1wy0on6 svg {
  display: none;
}

/* ----- Upload file CSS Start ----- */

.cs-choose-file ::-webkit-file-upload-button {
  display: none;
}

.cs-choose-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-choose-file .cs-icon {
  position: absolute;
  top: 50%;
  right: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background: var(--primary-main);
  border-radius: 2px;
  pointer-events: none;
}

.cs-choose-file .form-control {
  padding-block: 11px 10px;
  padding-inline: 16px 40px;
}

.cs-choose-file .cs-choose-file-box {
  width: 100%;
  margin-right: 16px;
}

.cs-choose-file-box:before {
  content: attr(title);
  position: absolute;
  top: 50%;
  left: 18px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  max-width: calc(100% - 60px);
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

/* ----- Upload file CSS End ----- */

/* ----- Common Switch start ----- */
.cs-switch-checkbox {
  position: absolute;
  height: 0;
  width: 0;
  visibility: hidden;
}

.cs-switch-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
  width: 28px;
  height: 16px;
  background: var(--neutral-40);
  border-radius: 20px;
  position: relative;
  -webkit-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  transition: background-color 0.2s;
  padding: 2px;
}

.cs-switch-label:disabled {
  background: var(--neutral-30);
}

.cs-switch-label .switch-button {
  content: '';
  position: absolute;
  top: 50%;
  left: 3px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 100%;
  -webkit-transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  -o-transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  background: var(--neutral-10);
}

.cs-switch-checkbox:checked+.cs-switch-label .switch-button {
  left: calc(100% - 3px);
  -webkit-transform: translateX(-100%) translateY(-50%);
  -ms-transform: translateX(-100%) translateY(-50%);
  transform: translateX(-100%) translateY(-50%);
  -webkit-transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  -o-transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
}

.cs-switch-label:active .switch-button {
  width: 12px;
}

input[type='checkbox']:checked~.cs-switch-label {
  background: var(--primary-main);
}

/* ----- Common Switch end -----  */

.password-requirements {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin-top: -10px;
}

.password-requirements li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.password-requirements li:not(li:last-child) {
  margin-bottom: 6px;
}

.password-requirements li span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 8px;
  width: 14px;
  height: 14px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.password-requirements li span svg {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0;
}

.password-requirements .cs-light-body-text-s span:nth-child(2) {
  margin-left: 6px;
  margin-right: 0;
  width: 16px;
  height: 16px;
}

/* ==========   END - Common Input fields  ========== */

/* ========== Accordion CSS Start ========== */
.cs-accordion {
  position: relative;
}

.cs-accordion .accordion-button {
  position: relative;
  padding: 0;
  border: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: transparent;
  z-index: 0;
}

.cs-accordion .accordion-item {
  border: 0;
  margin-bottom: 20px;
}

.accordion-header span {
  margin-right: 12px;
}

.cs-accordion .accordion-body {
  padding-inline: 0;
}

.accordion-body .cs-right-section {
  padding: 0;
  height: auto;
}

/* ========== Accordion CSS End ========== */

/* ========== OTP Layout CSS start ========== */

.otp-layout .otp-txt {
  width: 40px !important;
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  background: var(--neutral-10);
  height: 40px;
}

.otp-layout .otp-txt:focus-visible,
.otp-layout .otp-txt:active {
  border: 1px solid var(--neutral-50);
  outline: var(--neutral-50);
}

.otp-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 16px;
}

.otp-layout input.otp-txt {
  margin-left: 8px;
}

.otp-layout input.otp-txt:first-child {
  margin-left: 0px;
}

/* ========== OTP Layout CSS start ========== */

/* ========== START MultiStep form ========== */
.multi-step-header {
  text-align: left;
  margin-bottom: 24px;
}

.multi-step-progressbar {
  position: relative;
  width: 100%;
  height: 8px;
  background: var(--neutral-40);
  border-radius: 10px;
}

.multi-step-progressbar div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: var(--primary-main);
  border-radius: 10px;
  -webkit-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.multi-step-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 6px;
}

.multi-step-title .cs-success {
  width: 14px;
  height: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.multi-step-title .cs-success svg {
  margin: 0;
}

/* ========== END MultiStep form ========== */

/* ==========  START - Common Modal  ========== */

/* ----- Start Medium Size ----- */
.cs-md-modal {
  max-width: 522px;
}

.cs-md-modal .modal-content,
.cs-lg-modal .modal-content {
  border-radius: 4px;
  background: var(--neutral-10);
  -webkit-box-shadow: var(--box-shadow-two);
  box-shadow: var(--box-shadow-two);
  border: none;
}

.cs-md-modal .modal-header,
.cs-lg-modal .modal-header {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 52px 16px 52px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid var(--neutral-20);
  color: var(--neutral-100);
}

.cs-modalbox .btn-close {
  position: absolute;
  top: 26px;
  right: 22px;
  width: 22px;
  height: 22px;
  padding: 0;
  outline: none !important;
  border: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  display: none;
}

.modal-header span {
  cursor: pointer;
}

.modal-header .cs-close-btn {
  position: absolute;
  top: 50%;
  right: 22px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.modal-header .cs-previous-btn,
.cs-s-modal .modal-header .cs-previous-btn {
  position: absolute;
  top: 50%;
  left: 32px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.cs-s-modal .modal-header .cs-previous-btn {
  left: 26px;
}

.cs-md-modal .modal-title,
.cs-lg-modal .modal-title,
.cs-modalbox .modal-title {
  color: var(--neutral-100);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
}

.modal-title {
  line-height: 24px;
}

.modal-body {
  padding: 24px 32px;
}

.modal-footer {
  padding: 0px 32px 24px 32px;
  border: none;
}

/* ----- End Medium Size ----- */

/* ----- Start Large Size ----- */
.cs-lg-modal {
  max-width: 778px;
}

/* ----- End Large Size ----- */

.cs-modal-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 0;
  border: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.cs-modal-btn.cs-center-btn {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cs-modal-btn button:last-child {
  margin-left: 16px;
}

.modal-footer>* {
  margin: 0;
}

.payment-success .success-head {
  text-align: center;
  padding: 0 20px;
}

.payment-success .success-head img {
  margin-bottom: 24px;
}

.payment-success .modal-footer {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cs-common-modal-overlay:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--neutral-100);
  opacity: 0.5;
  pointer-events: none;
}

.cs-validation-popup {
  text-align: center;
}

.cs-validation-popup img {
  margin-bottom: 24px;
}

.cs-verification-modal h5 {
  margin-bottom: 24px;
}

.cs-verification-pin h5 {
  margin-bottom: 0 !important;
}

.delete-modal-content {
  text-align: center;
}

.delete-modal-content img {
  margin-bottom: 24px;
  width: 100px;
  height: 100px;
}

.cs-modal-blur:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(100%) blur(4px);
  pointer-events: none;
}

/* ========== END - Common Modal  ========== */

/* ========== START - Avatar CSS ========== */
.cs-avatar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 0;
  border-radius: 100%;
  font-weight: 400;
  margin: 0 auto;
  color: var(--neutral-80);
  overflow: hidden;
}

.cs-avatar-text {
  background-color: var(--neutral-50);
}

.cs-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.cs-avatar-lg {
  width: 160px;
  height: 160px;
  font-size: 22px;
  line-height: 32px;
}

.cs-avatar-md {
  width: 96px;
  height: 96px;
  font-size: 18px;
  line-height: 28px;
}

.cs-avatar-sm {
  width: 72px;
  height: 72px;
  font-size: 14px;
  line-height: 20px;
}

.cs-avatar-xs {
  width: 40px;
  height: 40px;
  font-size: 12px;
  line-height: 16px;
}

.cs-avatar-xxs {
  width: 36px;
  height: 36px;
  font-size: 10px;
  line-height: 16px;
}

.cs-profile .cs-user {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.cs-profile .cs-avatar {
  overflow: visible;
}

.cs-profile .cs-edit {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  bottom: 4px;
  right: 16px;
  width: 34px;
  height: 34px;
  border-radius: 100%;
  background: var(--primary-main);
  -webkit-box-shadow: var(--box-shadow-one);
  box-shadow: var(--box-shadow-one);
  cursor: pointer;
}

.cs-profile .cs-avatar-md .cs-edit {
  bottom: -2px;
  right: 8px;
  width: 28px;
  height: 28px;
}

.cs-profile .cs-avatar-md .cs-edit svg {
  width: 14px;
  height: 14px;
}

/* ========== END - Avatar CSS ========== */

/* ========== START - Common Upload files ========== */

.cs-upload-box {
  position: relative;
}

.cs-upload-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  border: 1px dashed var(--primary-border);
  background: var(--primary-surface);
  width: 100%;
  cursor: pointer;
}

.cs-upload-container .upload-icon {
  padding-bottom: 8px;
}

.cs-upload-container p span {
  color: var(--primary-main);
}

.cs-upload-container p {
  margin-bottom: 0;
}

.cs-upload-container .cs-upload-list {
  list-style-type: none;
}

.cs-upload-container:hover {
  border: 1px dashed var(--primary-hover);
}

/* ==========  END - Common Upload files  ========== */

/* ==========  START - Common Capsync Badge & Toast ========== */
/* ----- Badge Start ----- */
.cs-badge {
  padding: 3px 8px;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
}

.cs-badge.cs-primary {
  color: var(--primary-main);
  border: 1px solid var(--primary-border);
  background: var(--primary-surface) !important;
}

.cs-badge.cs-danger {
  color: var(--danger-pressed);
  border: 1px solid var(--danger-border);
  background: var(--danger-surface) !important;
}

.cs-badge.cs-info {
  color: var(--info-pressed);
  border: 1px solid var(--info-border);
  background: var(--info-surface) !important;
}

.cs-badge.cs-warning {
  color: var(--warning-pressed);
  border: 1px solid var(--warning-border);
  background: var(--warning-surface) !important;
}

.cs-badge.cs-success {
  color: var(--success-pressed);
  border: 1px solid var(--success-border);
  background: var(--success-surface) !important;
}

.cs-icon-badge-danger {
  background: var(--danger-surface);
}

.cs-icon-badge-neutral-20 {
  background: var(--neutral-20);
}

/* ----- Badge End ----- */

/* ----- Toast start ----- */
.cs-toast .Toastify__toast-container {
  margin: 65px 12px 0 0;
  width: 400px;
  padding: 0px;
}

.cs-toast .Toastify__toast {
  padding-block: 12px;
  min-height: auto;
  border-radius: 4px;
  -webkit-box-shadow: var(--box-shadow-two);
  box-shadow: var(--box-shadow-two);
  margin-bottom: 8px;
}

.cs-toast .Toastify__toast-theme--light {
  color: var(--neutral-100);
}

.cs-toast .Toastify__toast-body {
  padding: 0px;
  margin: 0px;
  color: var(--neutral-100);
  font-family: Readex Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
}

.cs-toast .Toastify__toast-icon {
  -webkit-margin-end: 8px;
  margin-inline-end: 8px;
  width: 16px;
}

.cs-toast .Toastify__close-button>svg {
  height: 100%;
  width: 100%;
  margin-top: 4px;
}

.cs-toast .Toastify__close-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 16px;
  width: 16px;
}

.cs-toast .Toastify__toast--success {
  border: 1px solid var(--success-border);
  background: var(--success-surface);
}

.cs-toast .Toastify__toast--success svg path {
  fill: var(--success-main);
}

.cs-toast .Toastify__toast--success .Toastify__progress-bar--success {
  background-color: var(--success-main);
}

.cs-toast .Toastify__toast--error {
  border: 1px solid var(--danger-border);
  background: var(--danger-surface);
}

.cs-toast .Toastify__toast--error svg path {
  fill: var(--danger-main);
}

.cs-toast .Toastify__toast--success .Toastify__progress-bar--error {
  background-color: var(--danger-main);
}

.cs-toast .Toastify__toast--success .Toastify__progress-bar--success {
  background-color: var(--success-main);
}

.cs-toast .Toastify__toast--warning {
  border: 1px solid var(--warning-border);
  background: var(--warning-surface);
}

.cs-toast .Toastify__toast--warning svg path {
  fill: var(--warning-main);
}

.cs-toast .Toastify__toast--success .Toastify__progress-bar--warning {
  background-color: var(--warning-main);
}

.cs-toast .Toastify__toast--info {
  border: 1px solid var(--info-border);
  background: var(--info-surface);
}

.cs-toast .Toastify__toast--info svg path {
  fill: var(--info-main);
}

.cs-toast .Toastify__toast--success .Toastify__progress-bar--info {
  background-color: var(--info-main);
}

/* ----- Toast End ----- */

/* ========== END - Common Capsync Badge & Toast ========== */

/* ========== START - Common Spinner ========== */

@-webkit-keyframes spinner-spin {
  0% {
    -webkit-transform: rotate(0turn);
    transform: rotate(0turn);
  }

  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes spinner-spin {
  0% {
    -webkit-transform: rotate(0turn);
    transform: rotate(0turn);
  }

  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.cs-common-spinner {
  width: 24px;
  height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-animation: spinner-spin 1s ease 0.1s infinite;
  animation: spinner-spin 1s ease 0.1s infinite;
}

/* ========== END - Common Spinner ========== */

/* ========== START - Common Table   ========== */

.cs-table {
  margin-bottom: 0px;
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  width: 100%;
  border-collapse: separate !important;
  border-spacing: 0;
  text-align: left;
}

.cs-table th:first-child {
  border-radius: 4px 0 0 0;
}

.cs-table th:last-child {
  border-radius: 0 4px 0 0;
}

.cs-table tr:last-child td:first-child {
  border-radius: 0 0 0 4px;
}

.cs-table tr:last-child td:last-child {
  border-radius: 0 0 4px 0;
}

.cs-table> :not(caption)>*>* {
  padding: 11px 16px;
  color: var(--neutral-90);
}

.cs-table .cs-thead {
  vertical-align: middle;
}

.cs-thead th {
  white-space: nowrap;
  border-bottom: 1px solid var(--neutral-50);
  background: var(--neutral-40);
  color: var(--neutral-100);
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  padding-block: 11px;
}

.cs-tbody td {
  vertical-align: middle;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  white-space: nowrap;
}

.cs-tbody tr {
  border-bottom: 1px solid var(--neutral-50);
}

.cs-tfoot-assets {
  color: var(--neutral-100);
}

.cs-tfoot-liability {
  color: var(--neutral-100);
}

.cs-tfoot-liability td {
  background: var(--liability-surface);
  color: var(--neutral-100);
  font-weight: 400;
}

.cs-tfoot-assets td {
  background: var(--asset-surface);
  color: var(--neutral-100);
  font-weight: 400;
  border-bottom: 0;
}

.go-next-page-div {
  text-align: right;
}

.go-next-page-div svg {
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.common-tooltip-margin {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.common-tooltip-margin span {
  margin-left: 6px;
}

.common-tooltip-margin svg path {
  fill: var(--neutral-70);
}

.listing-page-amount-width {
  width: 15%;
}

.header-gap-width {
  width: 12%;
}

.per-month-badge {
  border-radius: 4px;
  border: 1px solid var(--primary-border);
  background: var(--primary-surface);
  color: var(--primary-main);
  padding: 4px 8px;
  margin-left: 16px;
}

.per-month-gap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.amortization-schedule-box .cs-form-check-box,
.amortization-schedule-box .cs-form-check-box span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.go-next-page-div svg path {
  fill: var(--neutral-70);
}

.table-actions-container {
  padding: 4px;
  border-radius: 4px;
  background: var(--neutral-20);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.table-actions-container svg path {
  fill: var(--neutral-80);
}

.delete-icon-container svg path {
  fill: var(--danger-main);
}

.ownership-th,
.ownership-td {
  text-align: right;
  padding-right: 45px !important;
}

.ownership-th span {
  vertical-align: middle;
  margin-left: 8px;
}

.market-value-th span {
  vertical-align: bottom;
  margin-left: 8px;
}

.market-value-th span svg path {
  fill: var(--neutral-70);
}

.ownership-th span svg path {
  fill: var(--neutral-70);
}

.ownership-td .common-tooltip-margin span {
  margin-right: -24px;
}

.ownership-td .common-tooltip-margin {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.first-content-th {
  width: 20% !important;
}

.last-th-content {
  width: 8% !important;
}

.last-td-content {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  cursor: pointer;
}

.common-tooltip-margin p {
  white-space: nowrap;
}

.delete-outlined.cs-delete-btn.cs-disabled {
  background-color: var(--neutral-40) !important;
  border-color: var(--neutral-40) !important;
  cursor: not-allowed !important;
}

.delete-outlined.cs-delete-btn.cs-disabled svg path,
.common-tooltip-margin.cs-disabled svg path {
  fill: var(--neutral-60) !important;
}

.table-icons-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.table-icons-container .table-action {
  margin-right: 8px;
}

.cs-checkbox-table .cs-thead th,
.cs-checkbox-table .cs-tbody td {
  padding-block: 7px;
}

.cs-checkbox-table .cs-thead th:first-child,
.cs-checkbox-table .cs-tbody td:first-child {
  padding-inline: 10px 0;
}

.cs-common-no-record {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-block: 24px;
  background-color: var(--neutral-10);
  border-radius: 4px;
  border: 1px solid var(--neutral-50);
  margin-block: 16px;
}

.cs-table-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-table-icons span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 6px;
  border-radius: 4px;
  width: 26px;
  height: 26px;
  margin-left: 8px;
  background: var(--neutral-20);
  cursor: pointer;
}

.view-manage-user-modal .delete-outlined,
.cs-table-icons .cs-delete-btn {
  background: var(--danger-surface);
}

.cs-table-icons span:first-child {
  margin-left: 0;
}

.cs-checkbox-table .cs-tbody td {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.dashboard-individual-no-record .cs-common-no-record {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  /* height: 100%; */
  height: calc(100vh - 40vh);
  margin-bottom: 0;
  text-align: center;
  padding-block: 14vh;
}

.dashboard-individual-no-record .cs-common-no-record button {
  margin-top: 32px;
}

.dashboard-individual-no-record .no-record-with-img {
  width: 96px;
  min-width: 96px;
  max-width: 96px;
  height: 96px;
  min-height: 96px;
  max-height: 96px;
  margin-bottom: 8px;
}

.dashboard-individual-no-record .no-record-with-img img {
  width: 100%;
  height: 100%;
}

.dashboard-individual-no-record button .cs-icon svg path {
  fill: var(--neutral-10);
}

.cs-company-table-widget .cs-table-widget {
  max-width: 100%;
  overflow: unset;
  width: auto;
  padding: 0;
}

/* ========== END - Common Table ========== */

/* ========== START - Common Breadcrumb ========== */
.cs-section-top .cs-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
}

.cs-breadcrumb a {
  color: var(--neutral-80);
}

.cs-breadcrumb span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-breadcrumb span.cs-neutral-70 {
  padding: 0 4px;
}

/* ==========  END - Common Breadcrumb ========== */

/* ========== START - Files upload list ========== */
.cs-files {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.manage-profile-upload .cs-files:before {
  content: '';
  display: block;
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 24px;
  background: -o-linear-gradient(top, rgb(255 255 255), rgb(255 255 255 / 80%), transparent);
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      from(rgb(255 255 255)),
      color-stop(rgb(255 255 255 / 80%)),
      to(transparent));
  background: linear-gradient(180deg, rgb(255 255 255), rgb(255 255 255 / 80%), transparent);
  z-index: 1;
  pointer-events: none;
}

.manage-profile-upload .cs-files .cs-selected-files:first-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 24px;
  margin-bottom: 0;
}

.cs-files .cs-selected-files {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 8px 12px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--neutral-40);
  background: var(--neutral-10);
  margin-top: 16px;
}

.cs-files .cs-selected-files .tool-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
}

.cs-files .cs-selected-files .tool-content span.cs-neutral-60 {
  margin-right: 8px;
}

.cs-files .cs-selected-files .tool-content p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 0;
}

.cs-files .cs-selected-files .tool-content p span:nth-child(1) {
  color: var(--neutral-100);
}

.cs-files .cs-selected-files .tool-content p span:nth-child(2) {
  color: var(--neutral-80);
}

.cs-file-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 20%;
  justify-content: flex-end;
}

.cs-file-icon span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 28px;
  height: 28px;
  padding: 4px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 4px;
  margin-left: 8px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.cs-file-icon span:first-child {
  margin-left: 0;
}

.cs-files-names {
  display: block;
  width: 80%;
  padding: 0 8px;
}

.cs-files-names span {
  display: block;
}

.cs-files-names span:last-child {
  margin-top: 4px;
}

/* ========== END - Files upload list ========== */
/* ========== START - Offcanvas ========== */

.cs-off-canvas {
  width: 462px;
  padding: 40px 24px 24px 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 4px;
  background: var(--neutral-10);
}

.offcanvas.offcanvas-end {
  width: 378px;
  top: 10px;
  right: 10px;
  bottom: 10px;
}

.cs-off-canvas .cs-badge {
  display: inline-block;
}

.cs-off-canvas p {
  margin: 0px;
}

.cs-off-canvas .offcanvas-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0px;
  margin-bottom: 24px;
}

.cs-off-canvas .img-container {
  padding: 0px 0px 8px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
}

.off-canvas-avatar {
  position: relative;
  width: 96px;
  height: 96px;
}

.img-container img {
  height: 100%;
  width: 100%;
  border-radius: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.cs-off-canvas .img-container span {
  cursor: pointer;
}

.cs-off-canvas .img-container button {
  width: 28px;
  height: 28px;
}

.cs-off-canvas .header-information {
  padding: 0px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.cs-off-canvas .name-information {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.cs-off-canvas .name-information .vertical-line {
  width: 1px;
  height: 20px;
  background: var(--neutral-100);
  margin-right: 16px;
}

.cs-off-canvas .cs-semi-bold-h3 {
  color: var(--neutral-100);
  text-transform: capitalize;
  margin-right: 16px;
  word-break: break-all;
}

.header-information .name-information span:nth-child(2) {
  margin-right: 16px;
}

.cs-off-canvas .offcanvas-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0px;
  margin-right: -4px;
  padding-right: 4px;
  margin-bottom: 24px;
}

.cs-off-canvas .top-information-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 24px 16px;
  border-radius: 4px;
  background: var(--primary-surface);
  margin-bottom: 16px;
}

.cs-off-canvas .top-information-container:last-child {
  margin-bottom: 0;
}

.offcanvas-body .body-top-information {
  display: block;
}

.offcanvas-body .body-top-information .cs-medium-sub-heading-s {
  color: var(--neutral-60);
  margin-bottom: 4px;
}

.offcanvas-body .body-top-information .cs-regular-body-text-l {
  color: var(--neutral-100);
  margin-bottom: 16px;
  font-weight: 300;
}

.offcanvas-body .body-top-information:last-child .cs-regular-body-text-l:last-child {
  margin-bottom: 0;
}

.cs-off-canvas .offcanvas-header .name-information .cs-semi-bold-h3 {
  font-size: 14px;
}

.offcanvas-body .body-top-information .d-flex.align-items-center {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.cs-off-canvas .bottom-information-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 24px 16px;
  border-radius: 4px;
  background: var(--primary-surface);
}

.cs-off-canvas .radio-btn-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cs-off-canvas .cs-radio-btn,
.cs-radio-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 16px;
}

.cs-off-canvas .cs-radio-btn .radio-button,
.cs-radio-btn .radio-button {
  margin-right: 8px;
  cursor: pointer;
}

.cs-off-canvas .cs-radio-btn .cs-regular-body-text-m {
  color: var(--neutral-90);
}

.cs-off-canvas .cs-btn-group {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.designation-information span {
  text-transform: capitalize;
}

/* ========== END - Offcanvas ========== */
/* ========== START - Pagination ========== */

.cs-custom-pagination .cs-form-group {
  margin-bottom: 0;
}

.cs-custom-pagination .cs-regular-body-text-m>div {
  line-height: 16px;
  border: none;
  cursor: pointer;
  background: inherit;
}

.cs-custom-pagination .cs-regular-body-text-m .css-1nmdiq5-menu {
  background: var(--neutral-10);
}

.cs-custom-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  gap: 16px;
}

.cs-export-row {
  margin-top: 32px;
}

.cs-custom-pagination .cs-common-add-dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: -8px;
}

.cs-custom-pagination .cs-common-add-dropdown .dropdown .dropdown-toggle {
  color: var(--neutral-80) !important;
  font-weight: 300 !important;
  padding: 0;
  background: transparent;
  border: 0;
  width: auto;
  height: auto;
}

.cs-common-add-dropdown .dropdown-item.active,
.cs-common-add-dropdown .dropdown-item:active,
.cs-common-add-dropdown .dropdown-item:focus-visible {
  color: var(--primary-main) !important;
  background: var(--primary-surface) !important;
}

.cs-common-add-dropdown .dropdown .dropdown-menu.show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 8px 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  background: var(--neutral-10);
  -webkit-box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.12);
  border: none;
  max-height: 310px;
  overflow-y: auto;
  overflow-x: hidden;
}

.cs-custom-pagination .cs-common-add-dropdown .dropdown .dropdown-toggle svg {
  margin-inline: 8px;
}

.cs-custom-pagination .cs-common-add-dropdown .dropdown .dropdown-menu.show {
  width: auto;
  max-width: 100%;
  min-width: auto;
}

.cs-custom-pagination .cs-common-add-dropdown .dropdown .dropdown-menu.show .dropdown-item {
  padding: 6px 12px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cs-pagination-select .css-1dimb5e-singleValue {
  color: var(--neutral-80) !important;
}

.cs-pagination-select .css-1nmdiq5-menu {
  top: -200px !important;
}

.css-1nmdiq5-menu * {
  cursor: pointer !important;
}

.cs-pagination-arrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 16px;
}

.cs-pagination-arrow>button {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: medium;
  border-radius: 50%;
  height: auto;
  width: auto;
  padding: 0;
  cursor: pointer;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  color: rgba(0, 0, 0, 0.54);
  fill: rgba(0, 0, 0, 0.54);
  background-color: transparent;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-pagination-arrow>button:disabled {
  background: transparent;
}

.cs-pagination-arrow>button:disabled {
  cursor: unset;
  color: rgba(0, 0, 0, 0.18);
  fill: rgba(0, 0, 0, 0.18);
}

.cs-common-add-dropdown {
  grid-gap: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-pagination-arrow>button:hover:not(:disabled) {
  background-color: transparent;
}

.cs-custom-pagination [type='button']:not(:disabled) svg path,
.cs-custom-pagination button:not(:disabled) svg path {
  fill: var(--neutral-70);
}

.cs-custom-pagination .select-dropdown .css-13cymwt-control,
.cs-custom-pagination .cs-dropdown .css-13cymwt-control,
.cs-custom-pagination .select-dropdown .css-t3ipsp-control,
.cs-custom-pagination .cs-dropdown .css-t3ipsp-control {
  padding-inline: 0 6px !important;
}

/* ========== END - Pagination ========== */
.mobile-add-acc-icon,
.settings-main-section .settings-tab-dropdown.for-mob,
.account-change-password button {
  display: none;
}

.cs-radio-group .cs-radio-btn:last-child {
  margin-right: 0;
}

.settings-main-section .settings-tab-btn.cs-tabs.for-des {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cs-profile-action button {
  display: block;
}

.modal-content {
  border-radius: 4px;
  border: 0;
}

.cs-setting-checkbox input {
  margin-right: 8px;
  cursor: pointer;
}

.cs-complete-profile .select-dropdown .cs-form-group {
  margin-bottom: 0;
}

.cs-form-group input.form-control:disabled+span,
.cs-preview-image .cs-preview-title h5.cs-neutral-60 {
  cursor: not-allowed !important;
}

.select-dropdown .css-1nmdiq5-menu,
.cs-dropdown .css-1nmdiq5-menu {
  width: 100%;
  -webkit-box-shadow: var(--box-shadow-two);
  box-shadow: var(--box-shadow-two);
  border: 0;
}

.select-dropdown .css-1nmdiq5-menu * div,
.cs-dropdown .css-1nmdiq5-menu * div {
  color: var(--neutral-90);
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}

.select-dropdown .css-1nmdiq5-menu div[aria-selected='true'],
.cs-dropdown .css-1nmdiq5-menu div[aria-selected='true'] {
  background-color: var(--primary-surface);
  color: var(--primary-main);
}

.select-dropdown .css-1nmdiq5-menu div[aria-selected='false'],
.cs-dropdown .css-1nmdiq5-menu div[aria-selected='false'] {
  background-color: var(--neutral-10);
}

.select-dropdown .css-1nmdiq5-menu div[aria-selected='false']:hover,
.cs-dropdown .css-1nmdiq5-menu div[aria-selected='false']:hover {
  background-color: var(--primary-surface);
}

.cs-custom-pagination .select-dropdown .css-1nmdiq5-menu,
.cs-custom-pagination .cs-dropdown .css-1nmdiq5-menu {
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content;
}

.cs-modal-padding-null .modal-body {
  padding-bottom: 14px;
}

/* ========== START - Common Progress Indicator ========== */
.cs-progress-indicator,
.cs-progress-indicator span,
.cs-progress-indicator .cs-indicator,
.cs-progress-indicator .cs-indicator span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
}

.cs-progress-indicator .cs-indicator .cs-indicator-line {
  width: 40px;
  height: 2px;
  border-radius: 10px;
  background: var(--neutral-50);
  margin: 0 2px;
}

.cs-progress-indicator .cs-indicator.cs-primary .cs-indicator-line {
  background: var(--primary-main);
}

.cs-progress-indicator .cs-indicator.cs-success .cs-indicator-line {
  background: var(--success-main);
}

.cs-progress-indicator .cs-indicator.cs-danger .cs-indicator-line {
  background: var(--danger-main);
}

.cs-progress-indicator .cs-indicator.cs-warning .cs-indicator-line {
  background: var(--warning-main);
}

/* ========== END - Common Progress Indicator ========== */

.cs-new-user-modal .multiple-row-data.cs-disabled td {
  color: var(--neutral-50);
}

.cs-position-relative {
  position: relative;
}

.cs-modal-common-spinner {
  position: absolute;
  right: 10px;
}

.cs-btn-primary .cs-common-spinner {
  right: 4px;
}

.cs-update-btn.cs-btn-primary .cs-common-spinner {
  right: 16px;
}

.auth-footer .cs-btn-primary .cs-common-spinner {
  right: 12px;
}

.search-data-billing-history {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cs-line-clamp {
  word-break: break-word;
}