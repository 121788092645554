.cs-requests-tables {
  position: relative;
  margin-bottom: 32px;
}

.cs-requests-tables h4 {
  margin-bottom: 16px;
}

.cs-requests-tables .table {
  margin-bottom: 0;
}

.accordion-table,
.accordion-body .skeleton-table {
  padding: 0px 24px 12px 24px;
}

.signature-from .accordion-body .table tr td {
  border-bottom: 1px solid var(--neutral-50);
}

.signature-from .accordion .cs-table th:first-child {
  border-radius: 4px 0 0 4px;
}

.signature-from .accordion .cs-table th:last-child {
  border-radius: 0 4px 4px 0;
}

.signature-from .accordion .cs-thead th {
  border-bottom: 0px;
  background: var(--neutral-20);
}

.signature-from .cs-tbody tr td.accordion-table-collapse tr td {
  border-radius: 0;
}

.signature-from .cs-tbody :last-child td.accordion-table-collapse tr td:last-child {
  border-radius: 0 !important;
}

.cs-requests-tables .cs-progress-indicator .cs-indicator-icon {
  width: 24px;
  height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cs-requests-tables .cs-progress-indicator .cs-indicator-icon svg {
  width: 100%;
  height: 100%;
}

.cs-detail-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  cursor: pointer;
}

.cs-main-area .cs-detail-btn span:first-child {
  cursor: pointer;
  padding: 0;
  margin: 0;
  width: auto;
  height: auto;
}

.cs-detail-btn svg {
  width: 100%;
  height: 100%;
}

.cs-detail-btn .icon {
  width: 18px;
  height: 18px;
  margin-left: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cs-requests-tables .accordion-table th {
  background: var(--neutral-20);
  padding: 12px 16px;
  border-bottom: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.modal-body .row:last-child .col-md-12 .cs-form-group {
  margin-bottom: 0;
}

.cs-requests-tables .amortization-main-listing .cs-tbody .cs-first-radius {
  border-radius: 4px 0 0 4px !important;
}

.cs-requests-tables .amortization-main-listing .cs-tbody .cs-last-radius,
.cs-requests-tables .amortization-main-listing .cs-tbody:last-child td.accordion-table-collapse tr td:last-child.cs-last-radius {
  border-radius: 0 4px 4px 0 !important;
}

.cs-requests-tables .accordion-collapse {
  border-color: transparent;
}

.pfs-pdf-modal .modal-dialog {
  width: calc(100% - 250px);
  height: calc(100% - 70px);
  max-width: 100%;
  overflow: hidden;
}

.pfs-pdf-modal .modal-header {
  display: block;
  text-align: center;
}

.pfs-pdf-modal .pfs-pdf-box {
  padding: 24px 44px 0;
  height: 100%;
}

.pfs-pdf-modal .modal-content {
  height: 100%;
  border-color: transparent;
}

.pfs-pdf-modal .modal-footer {
  border: 0;
  padding: 30px 56px 32px;
}

.request-status-width {
  width: 15.4%;
}

.request-dashboard tr td:nth-child(2) {
  white-space: nowrap;
}

.offcanvas-divider {
  border-right: 2px solid;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
}

.off-canvas-request-email {
  overflow-wrap: break-word;
}

.body-top-information .offcanvas-divider {
  margin: 0 8px;
}

/*============================Decline Signature Request Modal==============================*/
.decline-signature-request p:first-child {
  margin-bottom: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cs-off-canvas .cs-btn-group button:last-child {
  margin-left: 16px;
}

.cs-off-canvas .cs-btn-group button:first-child {
  margin-left: 0;
}

.decline-signature-request .last-form-field {
  margin-bottom: 0;
}

/*============================Request Table Start==============================*/
.request-main-section .cs-search-bar {
  width: 100%;
  max-width: 350px;
  padding: 0;
}

.request-main-section .request-heading-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
}

.request-main-section .request-heading-icons span {
  cursor: pointer;
}

.request-main-section .cs-table-icons,
.request-main-section .req-accordion-table-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.request-main-section .cs-table-icons span {
  padding: 4px;
}

.request-main-section .cs-table-icons span svg {
  width: auto;
  height: auto;
}

.request-main-section .acc-icon-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  cursor: pointer;
}

.request-main-section .acc-icon-start {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  gap: 8px;
  cursor: pointer;
}

.request-main-section .acc-icon-start span {
  margin: 0;
}

.request-main-section .req-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;
  cursor: pointer;
}

.request-main-section .req-details span {
  padding: 0;
  margin: 0;
  background-color: transparent;
  width: auto;
  height: auto;
}

.request-main-section .cs-table-icons .req-accordion-icon {
  padding: 0;
  margin: 0;
  background-color: transparent;
  width: auto;
  height: auto;
}

.request-main-section .cs-table-icons .cs-common-spinner {
  -webkit-animation: none;
  animation: none;
}

.request-main-section .cs-table-icons .cs-common-spinner svg {
  width: 24px;
  height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-animation: spinner-spin 1s ease 0.1s infinite;
  animation: spinner-spin 1s ease 0.1s infinite;
}
.request-main-section .cs-table-icons .cs-common-spinner svg {
    width: auto;
    height: auto;
    display: block;
}
.request-main-section .cs-progress-indicator .cs-success svg {
  margin: 0;
}

.request-main-section .req-accordion-table-icons .cs-table-icons {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.request-main-section .cs-req-acc-table .accordion-item {
  border: 0;
  border-radius: 0;
}

.request-main-section .cs-req-table .req-acc-data {
  padding: 0 24px 8px;
}

.request-main-section .cs-req-table tr:last-child .req-acc-data {
  border-radius: 0 0 4px 4px !important;
}

.request-main-section .cs-req-acc-table .accordion-item tr td {
  border-bottom: 1px solid var(--neutral-50);
  border-radius: 0 !important;
}

.request-main-section .cs-req-acc-table .accordion-item tr:last-child td {
  border-bottom: 0;
}

.request-main-section .cs-req-acc-table .cs-table {
  border: 0;
  border-radius: 0;
}

.request-main-section .cs-req-acc-table .cs-table .cs-thead th {
  background-color: var(--neutral-20);
  border: 0;
}

.request-main-section .open-accordion {
  border-bottom: transparent;
  cursor: pointer;
}

.request-main-section .closed-accordion {
  cursor: pointer;
}

.request-main-section .cs-req-acc-table .cs-table th:first-child {
  border-radius: 4px 0 0 4px;
}

.request-main-section .cs-req-acc-table .cs-table th:last-child {
  border-radius: 0 4px 4px 0;
}

.request-main-section .company-filter .dropdown .dropdown-toggle {
  padding: 0;
  margin: 0;
  width: auto;
  height: auto;
  background-color: transparent;
  border: 0;
}

.request-main-section .company-filter .dropdown-menu.show {
  width: 100%;
  -webkit-box-shadow: var(--box-shadow-two);
  box-shadow: var(--box-shadow-two);
  border: 0;
  padding: 8px;
  border-radius: 4px;
}

.request-main-section .company-filter .dropdown-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  padding: 0;
  margin-bottom: 8px;
  cursor: default;
}

.request-main-section .company-filter .dropdown-item:last-child {
  margin-bottom: 0;
}

.request-main-section .company-filter .dropdown-item:hover {
  background-color: var(--neutral-10);
}

.request-main-section .company-filter .dropdown-item.active,
.request-main-section .company-filter .dropdown-item:active {
  background-color: var(--neutral-10);
}

.request-main-section .company-filter .active-filter-item,
.request-main-section .company-filter .default-filter-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid var(--neutral-50);
  border-radius: 4px;
  padding: 7px 16px;
  width: auto;
  cursor: pointer;
}

.request-main-section .company-filter .default-filter-item:hover {
  background-color: var(--primary-surface);
}

.request-main-section .company-filter .active-filter-item {
  border: 1px solid var(--primary-main);
}

.mob-scroller {
  margin-right: -4px;
  padding-right: 4px;
  overflow: hidden;
  overflow-y: auto;
}

.cs-off-canvas .canvas-cancel-btn {
  max-width: 100%;
}

.off-canvas-avatar .cs-avatar-text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
}

.request-main-section .cs-table-icons .cs-disabled span {
  cursor: not-allowed;
}

.pfs-pdf-modal .modal-dialog {
  width: calc(100% - 250px);
  height: calc(100% - 70px);
  max-width: 100%;
  overflow: hidden;
}

.pfs-pdf-modal .modal-header {
  display: block;
  text-align: center;
}

.pfs-pdf-modal .pfs-pdf-box {
  padding: 24px 44px 0;
  height: 100%;
  width: 100%;
}

.pfs-pdf-modal .modal-content {
  width: 100%;
  height: 100%;
  border-color: transparent;
}

.pfs-pdf-modal .modal-footer {
  border: 0;
  padding: 30px 56px 32px;
}

.pfs-pdf-box .cs-common-no-record {
  padding: 0;
  margin: 0;
  border: 0;
  width: 100%;
  height: 100%;
}

.request-main-section .request-skeleton .cs-table th,
.request-main-section .request-skeleton .cs-table td {
  min-width: auto !important;
}

/*============================Request Table End==============================*/

/*=========================================================================================*/
.display-signature-icon {
  display: none !important;
}

/* ============================= */
/* START Laptop - Viewport */
/* ============================= */
@media (max-width: 1799px) {
  .request-main-section .cs-req-table {
    max-width: 1799px;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 4px;
  }

  .request-main-section .cs-table th:nth-child(1),
  .request-main-section .cs-table th:nth-child(2),
  .request-main-section .cs-table th:nth-child(3) {
    min-width: 248px;
  }

  .request-main-section .cs-table th:nth-child(4) {
    min-width: 320px;
  }

  .request-main-section .cs-table th:nth-child(5) {
    min-width: 260px;
  }

  .request-main-section .cs-table th:nth-child(6) {
    min-width: 264px;
  }

  .request-main-section .cs-req-acc-table .cs-table th:nth-child(1),
  .request-main-section .cs-req-acc-table .cs-table th:nth-child(2),
  .request-main-section .cs-req-acc-table .cs-table th:nth-child(3),
  .request-main-section .cs-req-acc-table .cs-table th:nth-child(4),
  .request-main-section .cs-req-acc-table .cs-table th:nth-child(5),
  .request-main-section .cs-req-acc-table .cs-table th:nth-child(6) {
    min-width: 220px;
  }

  .request-main-section .cs-req-acc-table .cs-table th:nth-child(7) {
    min-width: 224px;
  }
}

@media (max-width: 1499px) {
  .request-main-section .cs-req-acc-table .cs-table-widget {
    max-width: 100%;
    overflow: initial;
  }

  .pfs-pdf-modal .pfs-pdf-box {
    padding: 0;
  }

  .pfs-pdf-modal .modal-body {
    padding: 24px;
  }

  .pfs-pdf-modal .modal-footer {
    padding: 0 24px 24px;
  }
}

@media (max-width: 1199px) {
  .request-main-section .cs-search-bar {
    max-width: 200px;
  }
}

/* ============================= */
/* END Laptop - Viewport */
/* ============================= */

/* ============================= */
/* START Tablet - Viewport*/
/* ============================= */
@media (max-width: 991px) {
  .pfs-pdf-modal .modal-dialog {
    width: 100%;
    height: 100%;
    padding: 24px;
    margin: 0 auto;
  }

  .pfs-pdf-modal .modal-content {
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .request-main-section .cs-search-bar {
    max-width: 100%;
    margin-top: 24px;
  }
}

/* ============================= */
/* END Tablet - Viewport */
/* ============================= */

/* ============================= */
/* START Mobile - Viewport*/
/* ============================= */
@media (max-width: 575px) {
  .request-main-section .cs-progress-indicator .cs-indicator .cs-indicator-line {
    width: 20px;
    height: 1px;
  }

  .request-main-section .cs-requests-tables .cs-progress-indicator .cs-indicator-icon svg {
    width: 20px;
    height: 20px;
  }

  .request-main-section .signature-request-from-box .cs-font-color-sub-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .request-main-section .signature-request-from-box .cs-detail-btn {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .request-main-section .signature-request-from-box .cs-detail-btn svg {
    width: 16px;
    height: 16px;
  }

  .request-main-section .cs-requests-tables {
    margin-bottom: 16px;
  }

  .request-main-section .cs-requests-tables h4 {
    margin-bottom: 8px;
  }

  .request-main-section .offcanvas-divider {
    height: 18px;
  }

  .request-main-section .cs-search-bar {
    margin-top: 16px;
  }

  .request-main-section .cs-table-icons .cs-common-spinner svg {
    width: 16px;
    height: 16px;
  }

  .pfs-pdf-modal .modal-dialog {
    padding: 16px;
  }

  .pfs-pdf-modal .modal-body {
    padding: 24px 16px;
  }

  .pfs-pdf-modal .modal-footer {
    padding: 0 16px 24px;
  }
}

@media (max-width: 425px) {}

@media (max-width: 360px) {}

@media (max-width: 320px) {}

/* ============================= */
/* END Mobile - Viewport */
/* ============================= */