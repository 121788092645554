/* ========== START Laptop - Viewport ========== */
@media screen and (max-height: 375px) {

  .cs-dashboard-logo,
  .sidebarClose .cs-dashboard-logo {
    margin-bottom: 20px;
    margin-top: 0px;
  }
}

@media screen and (min-width: 992px) {
  .isClose .cs-content-area {
    max-width: calc(100% - 72px);
  }

  .sidebarClose .cs-icon-logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .cs-mobile-toggle {
    display: none;
  }

  .sidebarClose .cs-nav-text,
  .cs-icon-logo,
  .sidebarClose .cs-full-logo {
    display: none;
  }

  .cs-sidebar-main-widget.sidebarClose {
    max-width: 72px;
    padding: 12px;
  }

  .sidebarClose .cs-notification-count {
    width: 8px;
    height: 8px;
    display: block;
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .sidebarClose .cs-nav-item .cs-side-nav {
    justify-content: center;
    line-height: 22px !important;
    margin-left: 0;
    max-height: 40px;
    max-width: 40px;
    min-height: 40px;
    min-width: 40px;
    padding: 8px;
  }

  .cs-sidebar-nav.nav {
    padding-inline: 4px;
  }

  .sidebarClose .cs-side-nav .cs-icon,
  .sidebarClose .cs-nav-dropdown .cs-nav-icon {
    margin-right: 0;
  }

  .sidebarClose .cs-dashboard-logo {
    width: auto;
    max-width: 40px;
    max-height: 42px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

  }
}

@media (max-width: 1899px) {
  .team-collaboration-sec .cs-table-widget {
    width: 100%;
    max-width: 1899px;
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 4px;
  }

  .team-collaboration-sec .cs-table-widget thead th:nth-child(1),
  .team-collaboration-sec .cs-table-widget tbody td:nth-child(1),
  .team-collaboration-sec .cs-table-widget thead th:nth-child(2),
  .team-collaboration-sec .cs-table-widget tbody td:nth-child(2) {
    min-width: auto;
  }

  .team-collaboration-sec .cs-table-widget tbody td:first-child,
  .team-collaboration-sec .cs-table-widget thead th:first-child {
    min-width: auto !important;
  }

  .team-collaboration-sec .cs-dropdown .css-1nmdiq5-menu {
    top: -110px;
  }

  .team-collaboration-sec .single-row-data .cs-dropdown:first-child .css-1nmdiq5-menu {
    top: auto;
  }

  .team-collaboration-sec .collaboration-permission-dropdown {
    position: relative;
    margin-bottom: 0 !important;
  }

  .team-collaboration-sec .collaboration-permission-dropdown .cs-form-dropdown,
  .team-collaboration-sec .cs-form-group,
  .collaboration-action-drop .cs-form-dropdown {
    margin: 0 !important;
  }

  .team-collaboration-sec .collaboration-permission-dropdown .dropdown-item {
    padding: 6px 12px;
  }

  .single-row-data .collaboration-permission-dropdown .dropdown-menu.show {
    -webkit-transform: translate(0, -50px) !important;
    -ms-transform: translate(0, -50px) !important;
    transform: translate(0, -50px) !important;
    z-index: 2;
  }

  .multiple-row-data .collaboration-permission-dropdown .dropdown-menu.show {
    top: auto !important;
    bottom: -6px !important;
    -webkit-transform: unset !important;
    -ms-transform: unset !important;
    transform: unset !important;
    z-index: 2;
  }
}

@media (max-width: 1499px) {
  .individual-main-section .lg-btn {
    max-width: 160px;
  }

  .dashboard-individual-no-record .no-record-with-img {
    width: 56px;
    min-width: 56px;
    max-width: 56px;
    height: 56px;
    min-height: 56px;
    max-height: 56px;
  }

  .dashboard-individual-no-record .cs-common-no-record button {
    margin-top: 24px;
  }

  .color-row .cs-btn-icon-tertiary {
    padding: 0;
  }

  .flexbox-fix:nth-child(2)>div:nth-child(2) {
    width: 32px !important;
    height: 32px !important;
  }

  .settings-main-section .flexbox-fix:nth-child(2)>div:nth-child(2) {
    width: 26px !important;
    height: 26px !important;
  }

  .settings-main-section .color-row .cs-btn-icon-tertiary {
    margin-top: -11px;
    font-size: 10px;
  }

  .settings-main-section .flexbox-fix:nth-child(2)>div:nth-child(2)>div:first-child:before {
    font-size: 10px;
  }

  .settings-main-section .auth-section .cs-title::after {
    width: 100%;
  }

  .cs-tabs .cs-stroke-bottom-tab {
    width: auto;
  }

  /* .single-row-data .collaboration-permission-dropdown .dropdown-menu.show {
    -webkit-transform: translate(0, -50px) !important;
    -ms-transform: translate(0, -50px) !important;
    transform: translate(0, -50px) !important;
  } */
}

@media (max-width: 1199px) {

  .cs-header-notification,
  .cs-header-data-share {
    grid-gap: 16px;
    padding: 8px 16px;
  }

  .cs-header-right,
  .cs-header-actions {
    grid-gap: 16px;
  }

  .category-card .card {
    padding: 16px;
    margin-bottom: 24px;
  }

  .individual-main-section .cs-add-row .cs-action-btn.for-des {
    display: none;
  }

  .individual-main-section .cs-add-row .dropdown-toggle.show.nav-link,
  .individual-main-section .cs-add-row .dropdown .dropdown-toggle,
  .individual-main-section .cs-add-row .nav-link:focus-visible {
    padding: 0;
    margin: 0;
    background-color: transparent;
    height: auto;
  }

  .individual-main-section .cs-add-row .dropdown-item {
    padding: 12px 16px;
    color: var(--neutral-90);
    width: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .individual-main-section .cs-add-row .dropdown-item span {
    margin-right: 8px;
    padding: 0;
    background: transparent;
    width: auto;
    height: auto;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: unset;
  }

  .individual-main-section .cs-add-row .dropdown-menu.show {
    width: 100%;
    -webkit-box-shadow: var(--box-shadow-two);
    box-shadow: var(--box-shadow-two);
    border: 0;
  }

  .individual-main-section .cs-add-row .cs-action-btn .dropdown-icon {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    height: 36px;
    background: var(--neutral-10);
  }

  .individual-main-section .cs-add-row .dropdown-item:hover,
  .individual-main-section .cs-add-row .dropdown-item:hover svg path {
    background: var(--primary-surface);
    color: var(--primary-main);
    fill: var(--primary-main);
  }

  .individual-main-section .client-top-bar .cs-add-row .cs-action-btn {
    position: relative;
    transform: unset;
    top: 0;
    left: 0;
  }

  .cs-search-bar .cs-form-group .form-control,
  .associate-add-more .cs-form-group .form-control {
    padding-right: 36px;
  }

  .cs-avatar-row .cs-avatar-xs {
    width: 56px;
    height: 56px;
  }

  .cs-tabs .cs-stroke-bottom-tab {
    padding: 10px;
  }

  .settings-main-section .branding-container .branding-left,
  .cs-main-layout .settings-main-section .branding-container .branding-left .cs-branding-accordion {
    padding-inline: 0 6px;
  }
}

/* ========== END Laptop - Viewport ========== */

/* ========== START Tablet - Viewport ========== */
@media (max-width: 991px) {

  .main-section,
  .settings-main-section {
    padding: 24px 12px;
  }

  .cs-header-widget {
    padding: 4px 12px;
  }

  .cs-section-top-bar {
    margin-bottom: 24px;
  }

  .card-icon {
    max-width: 64px;
    min-width: 64px;
    height: 64px;
  }

  .cs-company-individual .cs-tabs-flex {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: end;
    margin-bottom: 24px;
  }

  .cs-profile .cs-user {
    font-size: 10px;
    line-height: 16px;
  }

  .client-top-bar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .cs-dashboard-title {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .cs-section-top-bar .cs-search-bar {
    max-width: 100%;
    padding: 0;
  }

  .cs-table-user-action .dropdown .dropdown-toggle {
    padding: 8px 9px;
    height: 36px;
  }


  .companies-main-section .company-add-drop .cs-add-row.for-mob {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .companies-main-section .cs-table-user-action {
    margin: 0 16px 0 0;
  }

  .cs-navbar-dropdown-btn {
    margin-left: 16px;
  }

  .branding-section .welcome-text {
    display: none;
  }

  .branding-section .template3-section:before {
    left: 55%;
  }

  .settings-body>.container-fluid {
    padding: 0 24px;
  }

  .settings-main-section .settings-body .settings-tab-btn {
    padding: 16px 24px 0;
  }

  .cs-setting-page {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .cs-profile-action {
    margin-top: 24px;
  }

  .cs-setting-form .cs-btn-primary {
    margin-top: 24px;
  }

  .cs-main-layout .settings-main-section .branding-container {
    height: 100%;
  }

  .cs-main-layout::before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--neutral-100);
    z-index: 3;
    opacity: 0;
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
    visibility: hidden;
  }

  .cs-main-layout.isOpen::before {
    opacity: 0.3;
    visibility: visible;
  }

  .cs-mobile-toggle {
    position: relative;
    height: 5px;
  }

  .cs-mobile-toggle .cs-menu-toggle {
    position: absolute;
    right: 0;
    line-height: 15px;
  }

  .cs-content-area {
    max-width: 100% !important;
  }

  .cs-icon-logo {
    display: none;
  }

  .cs-sidebar-main-widget {
    left: 0;
  }

  .cs-sidebar-main-widget.sidebarClose {
    left: -272px;
  }

  .upgrade-navbar.cs-primary.for-des {
    display: block !important;
  }

  .upgrade-navbar.cs-primary.for-mob {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .cs-company-individual .cs-tabs-flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 24px;
  }

  .cs-company-individual .cs-tabs-flex .cs-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
  }

  .cs-company-individual .cs-tabs-flex .cs-tabs-note {
    margin-top: 8px;
  }

  .branding-section .template1-section .align-items-center.row>.col:first-child,
  .branding-section .template3-section .align-items-center.row>.col:first-child {
    display: none;
  }

  .branding-section .template1-section .align-items-center.row .template-right-section {
    padding: 0;
  }

  .branding-section .template3-section {
    padding: 44px 54px;
  }

  .branding-section .template4-section .template-right-section {
    padding: 0;
    padding-inline: 3vw;
  }

  .branding-section .template4-section .row:first-child .col:not(.col:first-child) {
    margin-left: -35vw;
  }
}

/* ==========END Tablet - Viewport ========== */

/* ========== START Mobile - Viewport ========== */
@media (max-width:676px) {
  .upgrade-navbar.cs-primary.for-des {
    display: none !important;
  }

  .upgrade-navbar.cs-primary.for-mob {
    position: relative;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    padding: 6px 10px 0px;
    z-index: 2;
  }
}

@media (max-width: 575px) {
  .cs-header-widget {
    padding: 2px 4px;
    max-height: 48px;
  }

  .main-section,
  .settings-main-section {
    padding: 16px 4px;
  }

  .dashboard-individual-no-record .no-record-with-img {
    width: 36px;
    min-width: 36px;
    max-width: 36px;
    height: 36px;
    min-height: 36px;
    max-height: 36px;
  }

  .dashboard-individual-no-record .cs-common-no-record button {
    margin-top: 16px;
  }

  .dashboard-individual-no-record .cs-common-no-record {
    font-size: 12px;
    line-height: 16px;
  }

  .cs-menu-nav .dropdown .dropdown-toggle {
    height: 100%;
    max-height: 28px;
    padding: 0;
  }

  .cs-header-profile .cs-verified.cs-icon {
    width: 12px;
    height: 12px;
  }

  .cs-header-profile .cs-verified.cs-icon img {
    width: 100%;
    height: 100%;
  }

  .cs-header-notification,
  .cs-header-data-share {
    height: 100%;
    max-height: 28px;
  }

  .cs-notification-badge {
    width: 6px;
    height: 6px;
    top: 4px;
    right: 0;
  }

  .cs-sidebar-main-widget {
    max-width: 250px;
  }

  .cs-header-center.cs-navbar-dropdown-btn .dropdown-toggle {
    font-size: 12px !important;
  }

  .dropdown-menu.cs-pfs-menu {
    max-width: 260px;
  }

  .cs-pfs-menu .pfs-item,
  .cs-pfs-menu .pfs-item-name {
    padding: 8px 24px;
  }

  .cs-company-individual .cs-tabs-flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 24px;
  }

  .cs-company-individual .cs-tabs-flex .cs-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
  }

  .cs-company-individual .cs-tabs-flex .cs-tabs .cs-stroke-bottom-tab,
  .cs-company-individual .cs-tabs-flex .cs-tabs .cs-stroke-default-tab {
    width: 100%;
  }

  .category-card .card {
    margin-bottom: 16px;
  }

  .card-icon {
    max-width: 50px;
    min-width: 50px;
    height: 50px;
  }

  .cs-dashboard-cards .card .card-icon .cs-icon,
  .individual-main-section .cs-add-row .dropdown-item {
    padding: 8px 16px;
  }

  .cs-common-no-record {
    padding-block: 16px;
  }

  .cs-section-top-bar,
  .cs-validation-popup img {
    margin-bottom: 16px;
  }

  .cs-notification .title {
    padding: 14px 10px;
  }

  .cs-header-dropdown .cs-notification {
    width: 100%;
  }

  .cs-header-right .cs-header-dropdown .dropdown-menu {
    left: -60vw;
  }

  .notification-link {
    padding: 8px 16px 8px 16px;
    min-height: auto;
  }

  .cs-view-all {
    padding: 8px 0;
  }

  .cs-dropdown-nav .dropdown-menu {
    width: 100%;
  }

  .cs-dropdown-nav .dropdown-menu a {
    padding: 8px 16px;
  }

  .cs-dropdown-nav .dropdown-menu a svg {
    margin-right: 8px;
  }

  .individual-table .cs-export-row,
  .companies-table .cs-export-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
  }

  .cs-export-row .export-btn {
    width: 100%;
    margin-bottom: 16px;
  }

  .cs-delete-modal .delete-filled svg {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
  }

  .cs-delete-individual .cs-modal-btn .cs-divider {
    margin: 4px auto;
  }

  .cs-modal-text {
    margin-top: 16px;
    margin-bottom: 0;
  }

  .cs-delete-modal .delete-filled {
    margin-top: 0;
  }



  .individual-main-section .cs-add-row .cs-action-btn .dropdown-icon {
    padding: 8px 10px;
  }

  .cs-delete-individual .cs-modal-btn {
    padding-inline: 50px;
  }

  .cs-delete-individual .cs-d-flex-btn .cs-btn-secondary {
    max-width: 100%;
  }

  .cs-delete-individual .cs-d-flex-btn span {
    right: -16px;
  }

  .cs-delete-individual .cs-modal-btn .cs-divider {
    width: auto;
  }

  .companies-main-section .cs-table-user-action {
    margin: 0 8px 0 0;
  }

  .cs-avatar-row .cs-avatar-xs {
    width: 10vw;
    height: 10vw;
  }

  .cs-avatar-container {
    margin-top: 16px;
  }

  .settings-body>.container-fluid {
    padding: 0 16px;
  }

  .cs-profile-details {
    margin-left: 0;
    margin-top: 16px;
  }

  .account-change-password button {
    display: block;
  }

  .cs-profile-action button {
    display: none;
  }

  .cs-profile-action {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 16px;
  }

  .cs-profile {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }

  .account-change-password {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .account-change-password>span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 6px 8px !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    border: 2px solid var(--primary-main);
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
    width: 100%;
    max-width: 151px;
    line-height: 20px;
    white-space: nowrap;
    max-width: 100%;
    margin: 0 16px 0 0;
  }

  .account-change-password button {
    line-height: 20px;
    height: 36px;
    display: block;
    max-width: 100%;
  }

  .edit-image-filled {
    width: 24px;
    height: 24px;
  }

  .edit-image-filled svg {
    width: 14px;
    min-width: 14px;
    max-width: 14px;
    height: 14px;
    min-height: 14px;
    max-height: 14px;
  }

  .cs-setting-page,
  .cs-setting-form {
    margin-top: 16px;
  }

  .cs-setting-form .cs-btn-primary {
    margin-top: 16px;
    max-width: 100%;
  }

  .cs-setting-page,
  .auth-right .cs-regular-h5,
  .cs-verification-modal h5 {
    margin-bottom: 16px;
  }

  .cs-setting-page.cs-audit-logs-sec {
    margin-top: 10px;
  }

  .mfa-box {
    margin-bottom: 16px;
    padding: 12px 16px;
  }

  .settings-main-section .mfa-setup-page .mfa-cards {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-gap: 24px;
  }

  .mfa-icon-text span svg {
    width: 40px;
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    min-height: 40px;
    max-height: 40px;
  }

  .settings-main-section .mfa-setup-page .settings-mfa-card,
  .cs-setting-form .row>div {
    max-width: 100%;
  }

  .cs-text-note>div,
  .cs-verification-modal .last-form-field.cs-form-group {
    margin-bottom: 0;
  }

  .resp-encryption {
    margin-top: 16px;
  }

  .team-collaboration-sec button.lg-btn,
  .cs-setting-page .cs-btn-icon-primary,
  .cs-audit-logs-sec .cs-search-bar {
    max-width: 100%;
    margin-bottom: 24px;
  }

  .team-collaboration-sec .cs-table-widget thead th:nth-child(1),
  .team-collaboration-sec .cs-table-widget tbody td:nth-child(1) {
    min-width: 160px;
  }

  .team-collaboration-sec .cs-table-widget thead th:nth-child(2),
  .team-collaboration-sec .cs-table-widget tbody td:nth-child(2) {
    min-width: 190px;
  }


  .upload-image-row .cs-choose-file,
  .client-login-section .cs-choose-file {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .upload-image-row .cs-choose-file .cs-choose-file-box,
  .client-login-section .cs-choose-file .cs-choose-file-box {
    margin: 0;
  }

  .upload-image-row .cs-choose-file .cs-btn-primary,
  .client-login-section .cs-choose-file .cs-btn-primary {
    max-width: 100%;
    margin-top: 16px;
  }

  .cs-branding-accordion .cs-btn-primary {
    max-width: 100%;
  }

  .flexbox-fix:nth-child(2)>div:nth-child(2) {
    width: 24px !important;
    height: 24px !important;
  }

  .flexbox-fix {
    width: 100%;
    padding-left: 0;
    margin-top: 16px;
  }

  .sketch-picker>div:first-child {
    width: 100% !important;
    height: 100% !important;
  }

  .sketch-picker {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100% !important;
    height: 320px !important;
    padding: 16px !important;
  }

  .branding-container .branding-left {
    padding-inline: 0;
  }

  .color-row .cs-btn-icon-tertiary {
    top: 47%;
    padding: 0 !important;
  }

  .template3-section .template-right-section {
    padding-inline: 16px;
  }

  .template1-section .template-right-section span svg,
  .template3-section .template-right-section span svg,
  .template4-section .template-right-section span svg {
    width: 10px;
    min-width: 10px;
    max-width: 10px;
    height: 10px;
    min-height: 10px;
    max-height: 10px;
  }

  .settings-main-section .template3-section,
  .template4-section .template-right-section {
    padding: 24px 32px;
  }

  .auth-section .cs-title::after {
    margin: 8px auto 14px;
  }

  .template-right-section .cs-regular-body-text-m,
  .template-right-section .cs-light-body-text-m {
    font-size: 6px;
    line-height: 10px;
  }

  .template-right-section .auth-footer {
    margin-block: 16px 0;
  }

  .welcome-text h4 {
    font-size: 10px;
    line-height: 14px;
  }

  .settings-main-section .fullscreen-preview .welcome-text h4 {
    font-size: calc(10px + 6px);
    line-height: calc(16px + 8px);
  }

  .settings-main-section .fullscreen-preview .welcome-text p {
    font-size: calc(8px + 4px);
    line-height: calc(12px + 4px);
    margin-top: 16px;
  }

  .branding-section .color-row .cs-btn-icon-tertiary {
    margin: 0;
    top: 44%;
    padding: 0 !important;
  }

  /* .branding-section .choose-image-slider .image-box {
    height: 70px;
  } */
  .branding-section .template1-section .align-items-center.row .template-right-section {
    padding-inline: 24px;
  }

  .branding-section .template4-section .row:first-child .col:not(.col:first-child) {
    padding-inline: unset;
  }

  .branding-section .template4-section .align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .branding-section .template4-section .template-right-section {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: calc(100% - 40px);
    height: calc(100% - 40px);
  }

  .branding-section .template4-section {
    padding-inline: 20px;
  }

  .branding-section .fullscreen-preview .template3-section {
    padding: 32px;
  }

  .branding-section .branding-section .branding-container .fullscreen-preview.dashboard-full-preview {
    padding-inline: 16px !important;
  }

  .branding-section .template2-section .template-right-section {
    max-width: 50vw;
    padding-inline: 16px;
  }

  .branding-section .template3-section {
    padding: 24px 32px;
  }

  .branding-section .template2-section .align-items-center.row>.col {
    padding-block: 20px;
  }

  .branding-section .fullscreen-preview .template2-section .align-items-center.row>.col {
    padding-block: 32px;
  }

  .branding-section .fullscreen-preview .template2-section .template-right-section {
    min-width: 80vw;
  }

  .custom-domain-section .cs-form-group:before {
    font-size: 10px;
  }

  .branding-section .template3-section:before {
    left: 62%;
  }

  .cs-table-icons .collaboration-action-drop span svg {
    width: 10px;
    height: 10px;
    min-width: 10px;
    max-width: 10px;
    min-height: 10px;
    max-height: 10px;
  }


}

@media (max-width: 425px) {
  .cs-delete-individual .cs-modal-btn {
    padding-inline: 30px;
  }
}

@media (max-width: 360px) {
  .cs-delete-individual .cs-modal-btn {
    padding-inline: 20px;
  }

  .cs-delete-individual .cs-d-flex-btn span {
    right: -12px;
  }
}

@media (max-width: 320px) {}

/* ========== END Mobile - Viewport ========== */